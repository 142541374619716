import { createAction, createReducer } from '@reduxjs/toolkit';

export interface NavigationState {
  leadPageIndex: number;
  consultantPageState: {
    selectedUserIds: object;
    matchingLeadsMultipleToggle: boolean;
  };
  consultantIsSwitched: boolean;
}

const initialState = {
  leadPageIndex: 0,
  consultantPageState: {
    selectedUserIds: {},
    matchingLeadsMultipleToggle: false
  },
  consultantIsSwitched: false
} as NavigationState;

export const setLeadPageIndex = createAction<number>('navigation/setLeadPageIndex');
export const setConsultantPageState = createAction<{
  selectedUserIds: object;
  matchingLeadsMultipleToggle: boolean;
}>('navigation/setConsultantPageState');
export const setConsultantIsSwitched = createAction<boolean>('navigation/setConsultantIsSwitched');

export default createReducer(initialState, (builder) => {
  builder.addCase(setLeadPageIndex, (state, action) => {
    state.leadPageIndex = action.payload;
  });
  builder.addCase(setConsultantPageState, (state, action) => {
    state.consultantPageState = action.payload;
  });
  builder.addCase(setConsultantIsSwitched, (state, action) => {
    state.consultantIsSwitched = action.payload;
  });
});
