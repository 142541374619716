import { ReactNode } from 'react';

import { Paper } from '@mui/material';

import styles from './_box.module.css';

type Props = {
  children: ReactNode;
  small?: boolean;
  height?: number;
  noMargin?: boolean;
};

const Section = (props: Props) => {
  const { children, small, height, noMargin } = props;

  return (
    <Paper
      elevation={2}
      sx={{
        padding: noMargin ? '0.5rem 0.5rem' : small ? '0.5rem 1.2rem' : '1rem 1.2rem',
        marginBottom: noMargin ? 0 : '0.7rem',
        height: height && height + 'px',
        marginRight: noMargin ? 0 : '0.7rem'
      }}
      className={styles['box-paper']}>
      {children}
    </Paper>
  );
};

export default Section;
