import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';


import * as TypesLeads from 'types/leads';

import styles from './_userRoles.module.css';

const displayTable = (roles: TypesLeads.UserRole[], rolesStatus: string) => {
  return (
    <div>
      <div className={styles['user-roles-list-head-row']}>
        <span className={styles['user-roles-list-header-title']} style={{ width: '25%' }}>{rolesStatus}</span>
        <span className={styles['user-roles-list-header-title']} style={{ width: '35%' }}>Client</span>
        <span className={styles['user-roles-list-header-title']} style={{ width: '10%' }}>Extent</span>
        <span className={styles['user-roles-list-header-title']} style={{ width: '15%' }}>Start date</span>
        <span className={styles['user-roles-list-header-title']} style={{ textAlign: 'right', width: '15%' }}>End date</span>
      </div>
      <div>
        {roles.map((role: TypesLeads.UserRole, index: number) => {
          return (
            <div key={index} className={styles['user-roles-list-row']} style={{ backgroundColor: index % 2 === 0 ? '#F0F0F0' : '#FFFFFF', padding: '0 15px' }}>

              <div className={styles['user-roles-list-cell']} style={{ width: '25%', fontWeight: '600' }}>
                {role.title}
              </div>
              <div className={rolesStatus === 'Current Role' ? styles['user-roles-list-cell-bold'] : styles['user-roles-list-cell']} style={{ width: '35%' }}>
                {role.customerName}
              </div>

              <div className={rolesStatus === 'Current Role' ? styles['user-roles-list-cell-bold'] : styles['user-roles-list-cell']} style={{ width: '10%' }}>
                {role.extent}%
              </div>

              <div className={rolesStatus === 'Current Role' ? styles['user-roles-list-cell-bold'] : styles['user-roles-list-cell']} style={{ width: '15%' }}>
                {role.startDate}
              </div>

              <div className={rolesStatus === 'Current Role' ? styles['user-roles-list-cell-bold'] : styles['user-roles-list-cell']} style={{ textAlign: 'right', width: '15%' }}>
                {role.endDate}
              </div>
            </div>
          );
        })}
      </div>
    </div >
  );
};

interface Props {
  roles: TypesLeads.UserRole[];
  rolesStatus: string;
}

const UserRoles = (props: Props) => {
  const { roles, rolesStatus } = props;

  return (
    <TableContainer component={Paper}>
      {displayTable(roles, rolesStatus)}
    </TableContainer>
  );
};

export default UserRoles;
