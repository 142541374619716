import { SelectChangeEvent, Box, FormControl, Select, MenuItem } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { FilterState } from 'redux/filterReducer';
import { RootState } from 'redux/store';

type Props = {
  setShownFilter: (value: string) => void;
  filters: FilterState;
};

const ShownFilter = (props: Props) => {
  const { setShownFilter, filters } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setShownFilter(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 170 }}>
      <FormControl fullWidth>
        <Select
          sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
          value={filters.showSelectedUsersFilter}
          onChange={handleChange}>
          <MenuItem value={'All'}>Show All</MenuItem>
          <MenuItem value={'Selected'}>Show Selected</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(ShownFilter);