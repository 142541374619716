import React from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';

type Props = {
  allSkills: string[];
  setSelectedSkills: (val: string[]) => void;
  filters: FilterState;
};

const SkillsFilter = (props: Props) => {
  const { allSkills, setSelectedSkills, filters } = props;

  return (
    <Autocomplete
      disablePortal
      multiple
      disableCloseOnSelect
      limitTags={3}
      id="skill-filter-picker"
      options={allSkills}
      onChange={(_, value: string[]) => setSelectedSkills(value)}
      value={filters.skillsFilter}
      sx={{
        width: '100%',
        borderRadius: '4px',
        '& .MuiAutocomplete-tag': {
          height: '25px'
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            label={<Typography sx={{ fontFamily: 'OpenSansRegular' }}>Skills</Typography>}

            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                height: '45px',
                backgroundColor: 'white',
                zIndex: '-1'
              },
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  height: 'auto'
                }
              }
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(SkillsFilter);