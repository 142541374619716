import { SelectChangeEvent, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { FilterState } from 'redux/filterReducer';
import { RootState } from 'redux/store';

type Props = {
    filters: FilterState;
    setAvailabilityFilter: (val: string) => void;
};

const AvailabilityFilter = (props: Props) => {
    const { filters, setAvailabilityFilter } = props;

    /* Prototype for use in later release? */
    // const [availability, setAvailability] = useState<number>(50);
    // function handleChange1(event: Event, value: number | number[], activeThumb: number): void {
    //     const availabilityValue = value as number;
    //     console.log("availability", availabilityValue)
    //     setAvailability(availabilityValue)
    //     setAvailabilityFilter(availabilityValue.toString());
    // }

    const handleChange = (event: SelectChangeEvent) => {
        const availability = event.target.value;
        setAvailabilityFilter(availability);
    };

    return (
        <Box sx={{ minWidth: 180, width: '30%' }}>
            <FormControl fullWidth>
                {/* Prototype for use in later release? */}
                {/* <Typography fontSize={12} id="input-slider">Availability: {'≥' + availability}%</Typography>
                <Slider
                    min={0}
                    max={100}
                    // marks
                    step={10}
                    aria-label='Availability'
                    defaultValue={50}
                    onChange={handleChange1}
                    // valueLabelDisplay="auto"
                    aria-labelledby="input-slider"
                /> */}
                <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                <Select
                    sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
                    value={filters.availabilityFilter?.toString()}
                    onChange={handleChange}
                    labelId="demo-simple-select-label"
                    label="Availabilitya"
                >
                    <MenuItem value={'0'}>All</MenuItem>
                    <MenuItem value={'90'}>Partly Assigned</MenuItem>
                    <MenuItem value={'100'}>Fully Available</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

const mapStateToProps = (state: RootState) => ({
    filters: state.filters
});

export default connect(mapStateToProps)(AvailabilityFilter);