import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Button, FormControl, TextField } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';

import styles from './_actionBar.module.css';

import { useAddLeadMutation } from 'redux/leadApi';
import { toast } from 'react-toastify';
import { TOASTS_ADD_AUTOCLOSE } from 'constants/constants';

function AddLead() {
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [addLead] = useAddLeadMutation();

  const formSchema = object({
    companyName: string().optional()
  });

  const { control, handleSubmit, reset } = useForm<{ companyName: string }>({
    resolver: yupResolver(formSchema),
    defaultValues: { companyName: '' }
  });

  const handleOpenInput = (): void => {
    setIsInputOpen(true);
  };

  const handleAdd = async (value: { companyName: string }): Promise<void> => {
    if (value.companyName.length > 0) {
      try {
        await addLead({ companyName: value.companyName }).unwrap();
        reset({ companyName: '' });
        setIsInputOpen(false);
      } catch (error: unknown) {
        console.log(error);
      }
    } else {
      toast.error('Missing company name', { toastId: 'errorAdd', autoClose: TOASTS_ADD_AUTOCLOSE });
    }
  };

  const handleCancel = (): void => {
    reset({ companyName: '' });
    setIsInputOpen(false);
  };

  return (
    <>
      {isInputOpen ? (
        <div className={styles['add-lead-opened']}>
          <FormControl>
            <Controller
              control={control}
              name="companyName"
              render={({ field }) => (
                <TextField
                  label="Consultant Broker"
                  variant="outlined"
                  size="small"
                  defaultValue={field.value}
                  onChange={field.onChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSubmit(handleAdd)();
                    } else if (event.key === "Escape") {
                      handleCancel();
                    }
                  }}
                  autoFocus={true}
                  className={styles['add-lead-input']}
                />
              )}
            />
          </FormControl>

          <Button
            variant="form"
            onClick={handleSubmit(handleAdd)}
            className={styles['add-lead-save']}>
            <DoneRoundedIcon className={styles['add-lead-save-icon']} />
          </Button>

          <Button variant="form" onClick={handleCancel} className={styles['add-lead-cancel']}>
            <CloseRoundedIcon className={styles['add-lead-cancel-icon']} />
          </Button>
        </div>
      ) : (
        <Button color="secondary" className={styles['add-lead-closed']} onClick={handleOpenInput}>
          <ButtonIcon>
            <AddRoundedIcon />
          </ButtonIcon>

          <span>Add lead</span>
        </Button>
      )}
    </>
  );
}

export default AddLead;
