import { SyntheticEvent } from 'react';
import { Checkbox } from '@mui/material';
import { CellProps, HeaderProps, Hooks, PluginHook } from 'react-table';

import * as TypesLeads from 'types/leads';
import usersStyles from './_displayUsers.module.css';
import styles from './../../leads/leads-table/_displayLeads.module.css';
import { getConditionalSelectHeaderCheckboxProps } from 'components/features/leads/leads-table/_functionsDisplayLeads';

function useRowSelectionConsultants(): PluginHook<TypesLeads.User> {
  return (hooks: Hooks<TypesLeads.User>) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header: (props: HeaderProps<TypesLeads.User>) => {
          const checkboxProps = getConditionalSelectHeaderCheckboxProps({
            headerProps: props as any,
            checkIfRowIsSelectable: () => true
          });

          return (
            <div>
              <Checkbox {...checkboxProps} className={styles['leads-content-header-checkbox']} />
            </div>
          );
        },
        Cell: ({ row }: CellProps<TypesLeads.User>) => {
          return (
            <div
              className={`${usersStyles['users-content-cell-checkbox-container']}`}
              onClick={(event: SyntheticEvent) => event.stopPropagation()}>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          );
        }
      },
      ...columns
    ]);
  };
}

export default useRowSelectionConsultants;
