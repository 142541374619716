import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { neuronApi } from './api';

import * as TypesRedux from 'types/redux';
import * as TypesLeads from 'types/leads';

const storedToken = localStorage?.getItem('auth');
const storedUser = localStorage?.getItem('user');

const initialState: TypesRedux.InitialStateAuth = {
  token: storedToken ? JSON.parse(storedToken) : null,
  user: storedUser ? JSON.parse(storedUser) : null,
  isLoggedIn: storedToken ? true : false,
  isLoading: true,
  isError: false,
  errorMessage: ''
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setToken: (
      state: TypesRedux.InitialStateAuth,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
    },
    logout: (state: TypesRedux.InitialStateAuth) => {
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('auth');
      localStorage.removeItem('user');
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<TypesRedux.InitialStateAuth>) => {
    builder
      .addMatcher(neuronApi.endpoints.login.matchPending, (state: TypesRedux.InitialStateAuth) => {
        state.isLoading = true;
        state.token = null;
        state.user = null;
      })
      .addMatcher(
        neuronApi.endpoints.login.matchFulfilled,
        (
          state: TypesRedux.InitialStateAuth,
          action: PayloadAction<{ token: string; user: TypesLeads.User }>
        ) => {
          state.isLoading = false;
          state.isLoggedIn = true;
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.user = action.payload.user;
          localStorage.setItem('auth', JSON.stringify(state.token));
          localStorage.setItem('user', JSON.stringify(state.user));
        }
      )
      .addMatcher(neuronApi.endpoints.login.matchRejected, (state: TypesRedux.InitialStateAuth) => {
        state.isLoading = false;
        state.token = null;
        state.user = null;
        state.isError = true;
      });
  }
});

export const { setToken, logout } = slice.actions;

export default slice.reducer;
