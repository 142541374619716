import { neuronApi } from './api';

import * as TypesLeads from 'types/leads';

const statisticsApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopCompanies: builder.query<TypesLeads.TopCompany[], string>({
      query: (query: string) => ({
        url: `leads/topCompanies?companyLimit=50&${query}`,
        method: 'GET'
      })
    }),
    getLeadFrequencies: builder.query<TypesLeads.LeadFrequencies, string>({
      query: (query: string) => ({
        url: 'leads/leadTagFrequency?' + query,
        method: 'GET'
      }),
      providesTags: ['Leads']
    })
  }),
  overrideExisting: false
});

export const { useGetTopCompaniesQuery, useGetLeadFrequenciesQuery } = statisticsApiSlice;
