import { useNavigate, useSearchParams } from 'react-router-dom';
import UsersFilters from './filter_users/UsersFilters';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useGetAllLeadsQuery } from 'redux/leadApi';
import { getFoundLead } from './_functionsActionbar';
import * as TypesLeads from 'types/leads';
import styles from './_actionBar.module.css';
import ShownFilter from './ShownFilter';
import LocationFilter from './LocationFilter';
import SkillsFilter from './SkillsFilter';
import { Button } from '@mui/material';
import { Row } from 'react-table';
import { connect } from 'react-redux';
import { setSearchText, toggleSearchStatus, setLeadDateRange, FilterState, setConsultantsLocationFilter, setLeadsLocationFilter, setShowSelectedUserFilter, setSkillsFilter, setUsersFilter, setAvailabilityFilter } from 'redux/filterReducer';
import { AppDispatch, RootState } from 'redux/store';
import AvailabilityFilter from './AvailabilityFilter';
import LeadInfo from './LeadInfo';
import LeadAgeFilter from './LeadAgeFilter';


type Props = {
  allLocations: string[];
  allSkills: string[];
  isLeadsView: boolean;
  setIsLeadsView: Dispatch<SetStateAction<boolean>>;
  selectedFlatRows: Row<TypesLeads.User>[];
  isForLead: boolean;
  filters: FilterState;
  setConsultantsLocationFilter: (val: string) => void;
  setLeadDateRange: (value: number) => void;
  setLeadsLocationFilter: (val: string) => void;
  setAvailabilityFilter: (val: string) => void;
  setShowSelectedUserFilter: (val: string) => void;
  setSkillsFilter: (val: string[]) => void;
  setUsersFilter: (val: string) => void;
};

function ActionBar(props: Props) {
  const {
    allLocations,
    allSkills,
    isLeadsView,
    setIsLeadsView,
    filters,
    setConsultantsLocationFilter,
    setLeadDateRange,
    setLeadsLocationFilter,
    setAvailabilityFilter,
    setShowSelectedUserFilter,
    setSkillsFilter,
    setUsersFilter
  } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get('forLead');
  const { data: leads, isFetching } = useGetAllLeadsQuery({ leadsAge: filters.leadsAge, leadsLocationFilter: filters.leadsLocationFilter });
  const [lead, setLead] = useState<TypesLeads.Lead | undefined>(undefined);

  const handleGoBackToLead = (): void => {
    navigate(`/leads/${leadId}`, { state: { returnLocation: 'leads' } });
  };

  useEffect(() => {
    if (leads && leadId) {
      const foundLead = getFoundLead(leads, leadId);
      setLead(foundLead);
    }
  }, [leadId, leads]);

  return (
    <div className={styles['action-bar']}>
      {leadId && (
        <div className={styles['action-bar-button-text']}>
          <span className={styles['action-bar-text']}>
            <LeadInfo lead={lead} isFetching={isFetching} handleGoBackToLead={handleGoBackToLead} />
          </span>
        </div>
      )}

      <div className={styles['action-bar-filters-wrapper']}>
        <ShownFilter setShownFilter={setShowSelectedUserFilter} />
        <LocationFilter allLocations={allLocations} setConsultantsLocationFilter={setConsultantsLocationFilter} setLeadsLocationFilter={setLeadsLocationFilter} />

        <LeadAgeFilter setLeadDateRange={setLeadDateRange} />

        {leadId && <AvailabilityFilter setAvailabilityFilter={setAvailabilityFilter} />}
        <SkillsFilter allSkills={allSkills} setSelectedSkills={setSkillsFilter} />
        <UsersFilters setUsersFilter={setUsersFilter} />

        <Button
          className={styles['action-bar-matchingLeads-button']}
          onClick={() => setIsLeadsView(!isLeadsView)}
          sx={{ height: '40px' }}
        >
          {isLeadsView ? 'Show Consultant Details' : 'Show Matching Leads'}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSearchText: (value: string) => dispatch(setSearchText(value)),
  toggleSearchStatus: (value: string) => dispatch(toggleSearchStatus(value)),
  setLeadDateRange: (value: number) => dispatch(setLeadDateRange(value)),
  setConsultantsLocationFilter: (value: string) => dispatch(setConsultantsLocationFilter(value)),
  setLeadsLocationFilter: (value: string) => dispatch(setLeadsLocationFilter(value)),
  setAvailabilityFilter: (value: string) => dispatch(setAvailabilityFilter(value)),
  setShownFilter: (value: string) => dispatch(setShowSelectedUserFilter(value)),
  setSkillsFilter: (value: string[]) => dispatch(setSkillsFilter(value)),
  setUsersFilter: (value: string) => dispatch(setUsersFilter(value)),
  setShowSelectedUserFilter: (value: string) => dispatch(setShowSelectedUserFilter(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
