import { Box } from '@mui/material';

import LeadStatus from './lead-status/LeadStatus';
import * as TypesLeads from 'types/leads';

import styles from './_displayLeads.module.css';

interface Props {
  lead: TypesLeads.Lead;
  fontFamily?: string;
}

const LeadStatusList = (props: Props) => {
  const { lead, fontFamily } = props;
  return (
    <Box component="li" className={styles['lead-status-list']}>
      {lead.answered?.value ? (
        <LeadStatus displayName="Answered" color="var(--status-color-answered)" fontFamily={'var(--font-family-table-regular)'} />
      ) : (
        <LeadStatus displayName="Unanswered" color="var(--status-color-default)" fontFamily={'var(--font-family-table-regular)'} />
      )}
      {lead.result?.value === false && (
        <LeadStatus displayName="Lost" color="var(--status-color-default)" fontFamily={'var(--font-family-table-regular)'} />
      )}
      {lead.result?.value === true && (
        <LeadStatus displayName="Won" color="var(--status-color-won)" fontFamily={'var(--font-family-table-regular)'} />
      )}
    </Box>
  );
};

export default LeadStatusList;
