import { SyntheticEvent } from 'react';
import { Checkbox } from '@mui/material';
import { CellProps, HeaderProps, Hooks, PluginHook, Row } from 'react-table';

import * as TypesLeads from 'types/leads';
import { getConditionalSelectHeaderCheckboxProps } from './_functionsDisplayLeads';
import styles from './_displayLeads.module.css';

function usePluginRowSelect(): PluginHook<TypesLeads.Lead> {
  return (hooks: Hooks<TypesLeads.Lead>) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header: (props: HeaderProps<TypesLeads.Lead>) => {
          const checkboxProps = getConditionalSelectHeaderCheckboxProps({
            headerProps: props,
            checkIfRowIsSelectable: (row: Row<TypesLeads.Lead>) =>
              Boolean(!row.original.irrelevant.value)
          });

          return (
            <div>
              <Checkbox {...checkboxProps} className={styles['leads-content-header-checkbox']} />
            </div>
          );
        },
        Cell: ({ row }: CellProps<TypesLeads.Lead>) => {
          const isIrrelevant = Boolean(row.original.irrelevant.value);

          return (
            <div
              className={`${styles['leads-content-cell-checkbox-container']} ${isIrrelevant ? styles['leads-content-cell-checkbox-disabled'] : ''
                }`}
              onClick={(event: SyntheticEvent) => event.stopPropagation()}>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          );
        }
      },
      ...columns
    ]);
  };
}

export default usePluginRowSelect;
