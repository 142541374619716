import { FormControl } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';

import StatusInput from './StatusInput';
import FormActionButton from '../form-actions/FormActionButton';
import FormActionBar from '../form-actions/FormActionBar';

import { useEditLeadMutation } from 'redux/leadApi';
import * as TypesLeads from 'types/leads';

import styles from './_status.module.css';

interface FormStatuses {
  answered: TypesLeads.Status;
  result: TypesLeads.Status;
  irrelevant: TypesLeads.Status;
  persistant?: TypesLeads.Status;
}

interface Props {
  lead: TypesLeads.Lead;
  isReadOnly: boolean;
}

const StatusForm = (props: Props) => {
  const { lead, isReadOnly } = props;

  const previousStatuses: FormStatuses = {
    answered: lead.answered,
    result: lead.result,
    irrelevant: lead.irrelevant,
    persistant: lead.persistant
  };
  const [editLead] = useEditLeadMutation();

  const statusSchema = object({
    answered: object<TypesLeads.Status>().nullable(),
    result: object<TypesLeads.Status>().nullable(),
    irrelevant: object<TypesLeads.Status>().nullable()
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormStatuses>({
    resolver: yupResolver(statusSchema),
    defaultValues: previousStatuses
  });

  const handleReset = () => {
    reset(previousStatuses);
  };

  const handleUpdate = (status: FormStatuses) => {
    reset(status);
    const { answered, result, irrelevant, persistant } = status;
    editLead({ leadId: lead.leadId, answered, result, irrelevant, persistant }).unwrap();
  };

  return (
    <>
      <FormActionBar title="Status">
        {isDirty && (
          <>
            <FormActionButton variant="clear" onClick={handleReset} />
            <FormActionButton variant="save" onClick={handleSubmit(handleUpdate)} />
          </>
        )}
      </FormActionBar>

      <FormControl className={styles['status-form']}>
        <div className={styles['status-form-row']}>
          <span className={styles['status-form-row-label']}>Answered</span>
          <Controller
            control={control}
            name="answered"
            render={({ field }) => (
              <StatusInput
                isReadOnly={isReadOnly}
                type="answered"
                selected={field.value}
                onChange={(answered: TypesLeads.Status) => {
                  field.onChange(answered);
                }}
              />
            )}
          />
        </div>
        <div className={styles['status-form-row']}>
          <span className={styles['status-form-row-label']}>Result</span>
          <Controller
            control={control}
            name="result"
            render={({ field }) => (
              <StatusInput
                isReadOnly={isReadOnly}
                type="result"
                selected={field.value}
                onChange={(result: TypesLeads.Status) => {
                  field.onChange(result);
                }}
              />
            )}
          />
        </div>
        <div className={styles['status-form-row']}>
          <span className={styles['status-form-row-label']}>Relevant</span>
          <Controller
            control={control}
            name="irrelevant"
            render={({ field }) => (
              <StatusInput
                type="irrelevant"
                selected={field.value}
                onChange={(result: TypesLeads.Status) => {
                  field.onChange(result);
                }}
              />
            )}
          />
        </div>
      </FormControl>
    </>
  );
};

export default StatusForm;
