import { neuronApi } from './api';

import * as TypesAiCompare from 'types/aiCompare';

const aiCompareApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getAiCompare: builder.query<TypesAiCompare.AiCompareResult, {userId: number; leadId: string}>({
      query: ({ userId, leadId }) => ({
        url: `aicompare/lead/${leadId}/user/${userId}`,
        method: 'GET',
      }),
      providesTags: ['Users', 'Leads']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetAiCompareQuery,
} = aiCompareApiSlice;
