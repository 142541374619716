import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import * as TypesLeads from 'types/leads';
import styles from './_displayConsultantLeads.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useGetAllLeadsQuery, useGetFindTopFiveCompetenceForUserQuery } from 'redux/leadApi';
import ConsultantLeadsRow from './ConsultantLeadsRow';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';

type Props = {
  user: TypesLeads.User;
  filters: FilterState;
};

const ConsultantLeads = (props: Props) => {
  const { user, filters } = props;
  const navigate = useNavigate();
  const [isConsultantOpen, setIsConsultantOpen] = useState(true);

  const inputParams = {
    userId: user.userId ?? 0,
    leadsAge: filters.leadsAge,
    locationName: filters.leadsLocationFilter
  }

  const {
    data: userWithCompetenceForLeads,
    isLoading,
    isError
  } = useGetFindTopFiveCompetenceForUserQuery(inputParams);

  const { data: leadsOriginal = [] } = useGetAllLeadsQuery({ leadsAge: filters.leadsAge, leadsLocationFilter: filters.leadsLocationFilter });
  // Old way of doing below, kept atm for possible rollback
  // const {
  //   data: userWithCompetenceForLeads,
  //   isLoading,
  //   isError
  // } = useGetFindCompetenceForUserQuery(parseInt(user.id ?? '0'));
  // const leadsMerged: TypesLeads.Lead[] = getLeadsWithCompetenceForUser(
  //   leadsOriginal,
  //   userWithCompetenceForLeads?.matches
  // );
  // const leadsFiltered = filterLeads(leadsMerged);

  // const leadsSortedByMatch = sortLeadsByMatch(leadsFiltered);

  // const userTopLeads = leadsSortedByMatch.slice(0, 5);

  const finalLeads = userWithCompetenceForLeads?.matches.map(lead => {
    const foundLead = leadsOriginal[leadsOriginal.findIndex(item => item.leadId === lead.leadId)];
    return {
      ...foundLead, competenceStatus: {
        absences: lead.absences,
        userId: lead.userId,
        leadId: lead.leadId,
        weight: lead.weight,
        matchWeights: {
          tag: lead.matchWeights.tag,
          location: lead.matchWeights.location,
          availability: lead.matchWeights.availability
        }
      }
    }
  })

  return (
    <>
      <div
        className={styles['users-selected-matching-leads-header']}
        onClick={() => setIsConsultantOpen(!isConsultantOpen)}>
        <div className={styles['users-selected-matching-leads-header-name-image']}>
          <Avatar
            className={styles['users-selected-matching-leads-image']}
            src={user.imageUrl}
          />
          <span style={{ width: '190px' }}>{`${user.firstName} ${user.lastName}`}</span>
          <p className={styles['users-selected-matching-leads-header-role-name']}>Role</p>
        </div>
        <div className={styles['users-selected-matching-leads-header-skills']}>
          <p className={styles['users-selected-matching-leads-header-skills-text']}>Skills</p>
          <p className={styles['users-selected-matching-leads-header-skills-text']}>Available</p>
          <p className={styles['users-selected-matching-leads-header-skills-text']}>Location</p>
          {isConsultantOpen ? (
            <ExpandMoreIcon className={styles['users-selected-matching-leads-header-chevron']} />
          ) : (
            <ExpandLessIcon className={styles['users-selected-matching-leads-header-chevron']} />
          )}
        </div>
      </div>

      {isLoading && <LoadingThreeDots />}

      {!isLoading && isError && <ErrorMessage center />}

      {!isLoading && finalLeads &&
        finalLeads.map(
          (lead, index) =>
            isConsultantOpen && <ConsultantLeadsRow key={index} lead={lead} navigate={navigate} userId={user.userId} />
        )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(ConsultantLeads);
