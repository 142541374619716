import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styles from './_timePeriod.module.css';
import moment from 'moment';

export class TimePeriod {
  name: string;
  startDate: string;
  endDate: string;

  constructor(name: string, months: number) {
    this.name = name;
    months = Math.max(1, months);

    const now: Date = new Date();

    const startDate: Date = new Date(now.getFullYear(), now.getMonth() - months + 1, 1, 0, 0, 0);
    const endDate: Date = new Date(now.getFullYear(), now.getMonth() + 1, 0, 0, 0, 0);

    this.startDate = moment(startDate).format('YYYY-MM-DD');
    this.endDate = moment(endDate).format('YYYY-MM-DD');
  }

  query(): string {
    return `startDate=${this.startDate}&endDate=${this.endDate}`;
  }
}

export const PERIOD_3_MONTHS = new TimePeriod('3 Months', 3);
export const PERIOD_6_MONTHS = new TimePeriod('6 Months', 6);
export const PERIOD_1_YEAR = new TimePeriod('1 Year', 12);

const timePeriods = [PERIOD_1_YEAR, PERIOD_6_MONTHS, PERIOD_3_MONTHS];

interface Props {
  onChange: (period: TimePeriod) => void;
  className?: string;
  timePeriod: TimePeriod;
}

const TimePeriodSelection = (props: Props) => {
  const { onChange, timePeriod, className } = props;

  const handleOnChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: TimePeriod
  ) => {
    if (value == undefined) return;
    onChange(value);
  };

  return (
    <div className={className}>
      <ToggleButtonGroup
        className={styles['time-period-group']}
        exclusive={true}
        color="primary"
        value={timePeriod}
        onChange={handleOnChange}>
        {timePeriods.map((period: TimePeriod) => {
          return (
            <ToggleButton key={period.name} value={period}>
              {period.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export default TimePeriodSelection;
