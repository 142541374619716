import React from 'react';

import styles from './_availabilityGraph.module.css';
import { Tooltip } from '@mui/material';
import { AbsenceGraphData } from './_functionsAvailabilityGraph';

interface Props {
  type: 'lead' | 'absence';
  start: number;
  end: number;
  tooltipTitle: string;
  graphData: AbsenceGraphData;
}

const UserAvailabilityRow = (props: Props) => {
  const { type, start, end, tooltipTitle, graphData } = props;

  const style = graphData.rowStyle(start, end);

  const className =
    styles[type === 'lead' ? 'availability-timeline-lead' : 'availability-timeline-absence'];

  const startDate = new Date(start).toDateString();
  const endDate = new Date(end).toDateString();

  const dateText = startDate == endDate ? startDate : `${startDate} - ${endDate}`;

  const tooltip = `${tooltipTitle}: ${dateText}`;

  return (
    <div className={styles['availability-timeline-row']}>
      <Tooltip title={tooltip} arrow placement="top" enterDelay={100} disableInteractive>
        <div className={className} style={style} />
      </Tooltip>
    </div>
  );
};

export default UserAvailabilityRow;
