import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Menu, MenuItem, PaperProps } from '@mui/material';

import { logout } from 'redux/authSlice';
import { RootState } from 'redux/store';
import * as TypesRedux from 'types/redux';

import styles from './_header.module.css';
import { useNavigate } from 'react-router-dom';

function UserInformation() {
  const navigate = useNavigate();
  const { user }: TypesRedux.InitialStateAuth = useSelector((state: RootState) => state.auth);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorElement);

  const menuProps: Partial<PaperProps | undefined> = {
    elevation: 0,
    sx: {
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1
    }
  };

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(null);
  };

  const handleLogout = (): void => {
    dispatch(logout());
  };

  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user?.imageUrl} />
      </Button>
      <Menu anchorEl={anchorElement} PaperProps={menuProps} open={isOpen} onClose={handleClose}>
        <MenuItem disabled={true} className={styles['user-information-wrapper']}>
          <Avatar
            alt={`${user?.firstName} ${user?.lastName}`}
            src={user?.imageUrl}
            className={styles['user-information-image']}
          />
          <span className={styles['user-information-name']}>
            {user?.firstName} {user?.lastName}
          </span>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </>
  );
}

export default UserInformation;
