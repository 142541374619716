import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import UsersContent from 'components/features/users/display-users/UsersContent';
import PageLayout from 'components/layouts/PageLayout';

import { useGetAllUsersQuery } from 'redux/userApi';
import { useGetFindCompetenceForLeadsQuery } from 'redux/leadApi';
import {
  getUsersWithCompetenceForLead,
  sortUsersByMatch
} from 'components/features/users/display-users/_functionsDisplayUsers';

import * as TypesLeads from 'types/leads';
import { FilterState } from 'redux/filterReducer';
import { connect } from 'react-redux';

interface Props {
  filters: FilterState;
}

const Users = (props: Props) => {
  const { filters } = props;
  const { consultantsLocationFilter } = filters;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [skip] = useState(location?.state?.skip == 'false' ? false : true)
  const searchParamForLead = String(searchParams.get('forLead'));
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(['relevance']);
  const [matchingLoaded, setMatchingLoaded] = useState(false)

  const { data: usersOriginal = [], isError } = useGetAllUsersQuery();
  const { data: usersWithCompetenceForLead, isLoading } =
    useGetFindCompetenceForLeadsQuery(searchParamForLead, { skip });

  const filteredUsers = usersOriginal.filter(val => val.location == consultantsLocationFilter)

  const usersMerged: TypesLeads.User[] = getUsersWithCompetenceForLead(
    filteredUsers.length > 0 ? filteredUsers : usersOriginal,
    usersWithCompetenceForLead?.matches
  );

  const usersSortedByMatch = sortUsersByMatch(usersMerged);

  if (!usersOriginal) {
    return (
      <PageLayout>
        <span>No data found</span>
      </PageLayout>
    );
  }

  useEffect(() => {
    const hasLeadId = searchParams.get('forLead') !== null;
    if (!hasLeadId) {
      setHiddenColumns(['relevance']);
    } else {
      setHiddenColumns(['assigned', 'location']);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isLoading && usersSortedByMatch.length > 0) {
      setMatchingLoaded(true)
    }
  }, [usersSortedByMatch])

  const availabilityFilterNumber = parseInt(filters.availabilityFilter) / 100;

  const filteredSortedUsers = usersSortedByMatch.filter(
    (user) => (user.competenceStatus?.matchWeights?.availability ?? 0) >= availabilityFilterNumber
  );

  return (
    <PageLayout>
      <UsersContent
        users={searchParams.get('forLead') !== null ? filteredSortedUsers : usersOriginal}
        isLoading={isLoading}
        isError={isError}
        matchingLoaded={matchingLoaded}
        hiddenColumns={hiddenColumns}
        returnToMultipleSelected={skip}
      />
    </PageLayout>
  );
};

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(Users);
