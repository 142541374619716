import { isListLengthOverLimit } from './_functionsViewAllLess';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

import styles from './_viewAllLess.module.css';
import { Button } from '@mui/material';

interface Props<T> {
  shouldDisplayAll: boolean;
  onClick: () => void;
  isTextOverLimit?: boolean;
  isText?: boolean;
  limitNumber?: number;
  data?: T[];
}

function ViewAllLess<T>(props: Props<T>) {
  const { shouldDisplayAll, isText, isTextOverLimit, limitNumber = 0, data, onClick } = props;

  const isOverLimit = isText ? isTextOverLimit : isListLengthOverLimit<T>(limitNumber, data);

  return (
    <div className={styles['view-all-less']}>
      {isOverLimit && (
        <Button 
        className={styles['view-all-less-button']} 
        onClick={onClick}
        size='small'>
          {shouldDisplayAll ? 'View less' : 'View all'}
          {shouldDisplayAll ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </Button>
      )}
    </div>
  );
}

export default ViewAllLess;
