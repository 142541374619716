import { NavLink, useLocation } from 'react-router-dom';

import styles from './_navigation.module.css';

const Navigation = () => {
  return (
    <nav className={styles['navigation']}>
      <ul className={styles['navigation-list']}>
        <NavLink to="/" className={`${styles['navigation-list-item']} ${useLocation().pathname.includes('/leads') && styles['navigation-list-item-active']}`} title="Leads">
          Leads
        </NavLink>
        <NavLink to="/consultants" className={styles['navigation-list-item']} title="Consultants" state={{ skip: true }}>
          Consultants
        </NavLink>
        <NavLink end to="/statistics" className={styles['navigation-list-item']} title="Statistics">
          Statistics
        </NavLink>
        <NavLink to="/about" className={styles['navigation-list-item']} title="About">
          About
        </NavLink>
      </ul>
    </nav>
  );
};

export default Navigation;
