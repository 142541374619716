import * as TypesLeads from 'types/leads';

export const isUserAssigned = (lead?: TypesLeads.Lead, user?: TypesLeads.User): boolean => {
  if (lead?.assignedConsultants && user) {
    return lead?.assignedConsultants.includes(user.userId);
  }
  return false;
};

export const getAssignedConsults = (
  lead?: TypesLeads.Lead,
  user?: TypesLeads.User
): (number | undefined)[] => {
  const userAssigned = isUserAssigned(lead, user);
  return lead && lead.assignedConsultants && !userAssigned && user
    ? [...lead.assignedConsultants, user.userId]
    : lead && lead.assignedConsultants && user
    ? lead.assignedConsultants.filter((id?: number) => id !== user.userId) ||
      lead?.assignedConsultants
    : [];
};
