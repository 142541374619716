import React from 'react';
import { toast } from 'react-toastify';
import styles from './_copyToClipboardButton.module.css';

type Props = {
  text: string;
};

const CopyToClipboardButton = (props: Props) => {
  const { text } = props;

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard!', { hideProgressBar: true });
  };

  return (
    <p className={styles['copy-to-clipboard-text']} onClick={handleClick}>
      {text}
    </p>
  );
};

export default CopyToClipboardButton;
