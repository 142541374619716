import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField
} from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';
import { useShareLeadMutation } from 'redux/leadApi';
import * as TypesLeads from 'types/leads';
import styles from './_lead.module.css';
import { Controller, Resolver, useForm } from 'react-hook-form';
import SalesPersonInput from './assign-person/SalesPersonInput';
import { array, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetAllUsersQuery } from 'redux/userApi';

interface Props {
    lead?: TypesLeads.Lead;
}

interface ShareLeadInterface {
    assignedPerson: number[],
    shareMessage: string,
}

function ShareLead(props: Props) {
    const { lead } = props;
    const { data = [] } = useGetAllUsersQuery();
    const [isOpen, setIsOpen] = useState(false);

    const [shareLead] = useShareLeadMutation();

    const assignSchema = object().shape({
        assignedPerson: array().of(number()),
        shareMessage: string().optional()
    });

    const defaultAssign = {
        assignedPerson: lead?.assignedConsultants,
        shareMessage: ''
    };

    const {
        control,
        reset,
        handleSubmit,
        getValues,
    } = useForm<ShareLeadInterface>({
        resolver: yupResolver(assignSchema) as Resolver<ShareLeadInterface, any>,
        defaultValues: defaultAssign
    });

    const handleShare = async (value: ShareLeadInterface) => {
        const email = data[data.findIndex(user => user.userId === value.assignedPerson[0])].email
        const body = {
            recipientEmailAddress: email,
            emailBody: value.shareMessage,
            leadId: lead?.leadId
        }
        shareLead(body);
        handleCloseDialog();
        reset();
    }

    const handleReset = () => {
        reset(defaultAssign);
    };

    const handleCloseDialog = (): void => {
        setIsOpen(false);
        handleReset();

    };

    const emailSubject = encodeURIComponent('I want to share this lead from Neuron')
    const emailBody = encodeURIComponent(
        `Hi!
Check out this lead in Neuron, you might find it interesting.
https://neuron.softhouselabs.com/leads/${lead?.leadId}

Please note that the text in this mail is auto generated by Neuron. If you have any questions or concerns regarding this mail, please contact the sender or the Neuron Team.`)

    return (
        <>
            <Button
                size="small"
                className={styles['lead-content-header-favorite-lead-button']}
                onClick={() => window.open(`https://mail.google.com/mail/?view=cm&fs=1&su=${emailSubject}&body=${emailBody}`)}>
                <ButtonIcon>
                    <ShareOutlinedIcon />
                </ButtonIcon>
                <span>Share lead</span>
            </Button>

            <Dialog open={isOpen} onClose={handleCloseDialog}>
                <DialogTitle sx={{ fontWeight: 'bold', paddingBottom: '0px' }}>Share lead</DialogTitle>
                <DialogTitle sx={{ paddingTop: '0px', fontSize: '16px' }}>{`${lead?.role} - ${lead?.companyName}`}</DialogTitle>

                <DialogContent>
                    <FormControl fullWidth className={styles['assign-person-form']}>
                        <Controller
                            control={control}
                            name="shareMessage"
                            render={({ field }) => (
                                <TextField
                                    className={styles['comments-form-text-input']}
                                    size="small"
                                    autoComplete="off"
                                    multiline
                                    autoFocus
                                    rows={3}
                                    value={field.value}
                                    placeholder='Optional message'
                                    onChange={(event: any) => field.onChange(event.target.value)}
                                    sx={{ marginBottom: '10px' }}
                                />
                            )} />

                        <DialogContentText>Recipient</DialogContentText>

                        <div className={styles['assign-consult-form-row']}>
                            <Controller
                                control={control}
                                name="assignedPerson"
                                render={({ field }) => (
                                    <SalesPersonInput
                                        options={data}
                                        assigned={field.value}
                                        onAssign={(data: (number | undefined)[] | undefined) => {
                                            field.onChange(data);
                                        }}
                                        share={true}
                                    />
                                )}
                            />
                        </div>
                    </FormControl>
                </DialogContent>

                <DialogActions className={styles['lead-header-dialog-footer']}>
                    <Button
                        onClick={handleCloseDialog}
                        sx={{ '&&': { marginRight: '5px' } }}
                        className={styles['lead-content-header-delete-lead-button']}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit(handleShare)}
                        sx={{ '&&': { marginRight: '5px' } }}
                        disabled={!getValues().assignedPerson}
                        className={styles['lead-content-header-favorite-lead-button']}>
                        Share Lead
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ShareLead;
