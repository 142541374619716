import SkillsLineChart from 'components/features/statistics/display-leads-skills/SkillsLineChart';
import LeadsBarChart from 'components/features/statistics/display-leads-skills/LeadsBarChart';
import CompaniesStatList from 'components/features/statistics/display-companies/CompaniesStatisticList';
import PageLayout from 'components/layouts/PageLayout';

import styles from './_statistics.module.css';
import TimePeriodSelection, {
  PERIOD_3_MONTHS
} from 'components/features/statistics/time-period/TimePeriodSelection';
import { useState } from 'react';

const Statistics = () => {
  const [timePeriod, setTimePeriod] = useState(PERIOD_3_MONTHS);

  return (
    <PageLayout>
      <div className={styles['statistics-charts']}>
        <TimePeriodSelection
          className={styles['statistics-charts-period']}
          timePeriod={timePeriod}
          onChange={setTimePeriod}
        />
        <div className={styles['statistics-charts-leads']}>
          <LeadsBarChart timePeriod={timePeriod} />
        </div>
        <div className={styles['statistics-charts-skills']}>
          <SkillsLineChart timePeriod={timePeriod} />
        </div>
        <div className={styles['statistics-companies']}>
          <CompaniesStatList timePeriod={timePeriod} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Statistics;
