import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { TOASTS_ADD_AUTOCLOSE } from 'constants/constants';
import { toast } from 'react-toastify';
import { logout } from 'redux/authSlice';

export const authenticatedCheckMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.originalStatus === 401) {
      dispatch(logout());
      toast.info('You were logged out', { autoClose: TOASTS_ADD_AUTOCLOSE });
    }
    return next(action);
  };

export const onResponseMiddleware: Middleware = () => (next) => (action) => {
  const { meta: requestInfo } = action;
  const endpointName = requestInfo?.arg?.endpointName;
  const successfullRequest = requestInfo?.requestStatus === 'fulfilled';
  if (endpointName === 'editLead' && successfullRequest) {
    toast.success('Lead was updated', { toastId: 'editLead' });
  }
  if (endpointName === 'addLead' && successfullRequest) {
    toast.success('Lead was added', { toastId: 'addLead' });
  }
  return next(action);
};
