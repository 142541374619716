import React, { useCallback } from 'react';
// import { connect, useDispatch, useSelector } from 'react-redux';
import { Avatar, Box } from '@mui/material';

import * as TypesLeads from 'types/leads';

import styles from '../_displayUsers.module.css';
import { useSearchParams } from 'react-router-dom';

import AssignUser from '../../assign-user/AssignUser';
import UserSkills from '../UserSkills';
import UserAvailability from '../../availability-graph/UserAvailability';
import { getAvailabilityTitle } from '../_functionsDisplayUsers';
import UserRoles from '../role/UserRoles';
import AiCompareButton from '../../../ai-compare/AiCompareButton';

interface Props {
  user?: TypesLeads.User;
}

function UserDetails(props: Props) {
  const { user } = props;
  const [searchParams] = useSearchParams();

  const forLead = searchParams.get('forLead');
  const isMatching = forLead != null  // if not matching against a lead, this boolean will be false

  const displaySkills = useCallback(
    () => <UserSkills user={user} isMatching={isMatching} />,
    [user]
  );

  if (user == null)
    return <Box component="div" className={styles['users-table-container-information']} />;

  const activeRoles = user.roles.filter((role) => role.isCurrentRole);
  const inactiveRoles = user.roles.filter((role) => !role.isCurrentRole);
  const previousRoles = inactiveRoles.filter((role) => Date.parse(role.endDate) < Date.now());
  const upcomingRoles = inactiveRoles.filter((role) => Date.parse(role.endDate) > Date.now());

  return (
    <>
      <div className={styles['users-table-container-information-profile']}>
        <div className={styles['users-table-container-information-profile-information']}>
          <Avatar
            src={user?.imageUrl}
            className={styles['users-table-container-information-profile-image']}
          />
          <div className={styles['users-table-container-information-profile-section']}>
            <span className={styles['users-table-container-information-profile-section-name']}>{`${user?.firstName || ''
              } ${user?.lastName || ''}`}</span>
            <span className={styles['users-table-container-information-profile-section-title']}>
              {user?.title}
            </span>
            <span
              className={
                user?.location
                  ? styles['users-table-container-information-body-section-data']
                  : styles['users-table-container-information-body-section-no-data']
              }>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: '14px' }}>
                <path
                  d="M0 18V4H4V0H14V8H18V18H10V14H8V18H0ZM2 16H4V14H2V16ZM2 12H4V10H2V12ZM2 8H4V6H2V8ZM6 12H8V10H6V12ZM6 8H8V6H6V8ZM6 4H8V2H6V4ZM10 12H12V10H10V12ZM10 8H12V6H10V8ZM10 4H12V2H10V4ZM14 16H16V14H14V16ZM14 12H16V10H14V12Z"
                  fill="#333333"
                />
              </svg>
              {user?.location || 'No data'}
            </span>
          </div>
        </div>
        <div className={styles['users-table-container-information-profile-actions']}>
          {isMatching && <AssignUser user={user} />}
          {isMatching && <AiCompareButton userId={user.userId} leadId={forLead || '0'} />}
          {user?.cinodeUrl ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={user?.cinodeUrl}
              className={styles['users-table-container-information-profile-section-cinode']}>
              {'View on Cinode'}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: '100%', marginLeft: '5px' }}>
                <path
                  d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z"
                  fill="#334F68"
                />
              </svg>
            </a>
          ) : null}
        </div>
      </div>
      <div className={styles['users-table-container-information-body']}>
        <div className={styles['users-table-container-information-body-section-skills']}>
          {displaySkills()}
        </div>
        {isMatching && !!user?.competenceStatus?.leadId && (
          <div className={styles['users-table-container-information-body-section']}>
            <span className={styles['users-table-container-information-bdy-section-title']}>
              {getAvailabilityTitle(user?.competenceStatus.matchWeights?.availability)}
            </span>
            <UserAvailability user={user} />
          </div>
        )}
        <div className={styles['users-table-container-information-body-section']}>
          {activeRoles.length > 0 ? (
            <UserRoles
              roles={activeRoles}
              rolesStatus={`Current Role${activeRoles.length != 1 ? 's' : ''}`}
            />
          ) : (
            ''
          )}
        </div>
        <div className={styles['users-table-container-information-body-section']}>
          {upcomingRoles.length > 0 ? (
            <UserRoles
              roles={upcomingRoles}
              rolesStatus={`Upcoming Role${activeRoles.length != 1 ? 's' : ''}`}
            />
          ) : (
            ''
          )}
        </div>
        <div className={styles['users-table-container-information-body-section']}>
          {previousRoles.length > 0 ? (
            <UserRoles
              roles={previousRoles}
              rolesStatus={`Previous Role${activeRoles.length != 1 ? 's' : ''}`}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

export default UserDetails;
