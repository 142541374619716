import { Button } from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';
import styles from './_pagination.module.css';
import { RowInformation } from 'components/features/users/display-users/UsersTable';

interface Props {
  previousPage: () => void;
  nextPage: () => void;
  gotoPage: (pageNumber: number) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptionsLength: number;
  pageCount: number;
  rowInformation?: RowInformation;
  pageIndex: number;
}

function Pagination(props: Props) {
  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageOptionsLength,
    pageCount
  } = props;

  function renderPageInformation(): JSX.Element {
    return <div className={styles['pagination-text-number']}>
      <span className={styles['pagination-text']}>Page</span>
      <span className={`${styles['pagination-number']} ${styles['first']}`}>{pageIndex + 1}</span>
      <span className={styles['pagination-text']}>of</span>
      <span className={styles['pagination-number']}>{pageOptionsLength}</span>
    </div>
  }

  function renderRowInformation(): JSX.Element {
    return <div className={styles['pagination-text-number']}>
      <span className={styles['pagination-text']}>Showing</span>
      <span className={`${styles['pagination-number']} ${styles['first']}`}>{props.rowInformation?.firstRowNbr}-{props.rowInformation?.lastRowNbr}</span>
      <span className={styles['pagination-text']}>of</span>
      <span className={styles['pagination-number']}>{props.rowInformation?.totalRowsNbr}</span>
    </div>
  }

  function renderPaginationInformation(): JSX.Element {
    return (
      props.rowInformation ?
        <div>
          {renderPageInformation()}
          {renderRowInformation()}
        </div>
        :
        renderPageInformation()
    )
  }

  return (
    <div className={styles['pagination']}>
      <div className={styles['pagination-buttons-container']}>
        <Button
          size="small"
          className={styles['pagination-button']}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}>
          <ButtonIcon>
            <KeyboardDoubleArrowLeftRoundedIcon />
          </ButtonIcon>
        </Button>

        <Button size="small" onClick={previousPage} disabled={!canPreviousPage}>
          <ButtonIcon small={false}>
            <NavigateBeforeRoundedIcon />
          </ButtonIcon>
        </Button>
      </div>

      {renderPaginationInformation()}

      <div className={styles['pagination-buttons-container']}>
        <Button
          size="small"
          className={styles['pagination-button']}
          onClick={nextPage}
          disabled={!canNextPage}>
          <ButtonIcon left small={false}>
            <NavigateNextRoundedIcon />
          </ButtonIcon>
        </Button>

        <Button size="small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <ButtonIcon>
            <KeyboardDoubleArrowRightRoundedIcon />
          </ButtonIcon>
        </Button>
      </div>
    </div>
  );
}

export default Pagination;
