import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';

import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ClickAwayListener } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import styles from './_information.module.css';

interface InputProps {
  className?: string;
  placeholder?: string;
  format?: string;
}

interface Props {
  value: string;
  label?: string;
  inputProps?: InputProps;
  className?: string;
  editableOnDefault?: boolean;
  isReadOnly: boolean;
  onChange: (value: string) => void;
  minValue?: string;
  maxValue?: string;
}

const InformationDateInput = (props: Props) => {
  const {
    value,
    label = '',
    inputProps = {},
    className,
    editableOnDefault = false,
    isReadOnly,
    onChange,
    minValue,
    maxValue
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(editableOnDefault);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dateButtonRef = useRef<HTMLButtonElement>(null);
  const errorMessage = 'Invalid date';

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const handleClickInput = () => {
    if (!isReadOnly) {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    if (wrapperRef.current || inputRef.current) {
      inputRef.current?.focus();
      setAnchorElement(wrapperRef.current);
    }
  }, [isEditing]);

  const handleOnDateChange = (event: Moment | null) => {
    const date = moment(event?.toDate()).format('YYYY-MM-DD');
    if (date) {
      onChange(date);
    }
  };

  const handleOnBlur = () => {
    if (value !== errorMessage) {
      setIsEditing(false);
    }
  };

  return (
    <div className={`${styles['information-date-input']} ${className}`} ref={wrapperRef}>
      {isEditing && !isReadOnly ? (
        <ClickAwayListener onClickAway={handleOnBlur}>
          <div style={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label={label || ''}
                value={value}
                minDate={moment(minValue || moment(minValue).subtract(1, 'M'))}
                maxDate={moment(maxValue || moment(maxValue).add(2, 'y'))}
                inputFormat="YYYY/MM/DD"
                PopperProps={{
                  anchorEl: anchorElement
                }}
                onChange={handleOnDateChange}
                ref={wrapperRef}
                OpenPickerButtonProps={{
                  ref: dateButtonRef
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    onKeyDown={handleOnKeyDown}
                    sx={{ minWidth: '100%' }}
                    inputRef={inputRef}
                    helperText={value === errorMessage ? errorMessage : ''}
                    error={value === errorMessage}
                    FormHelperTextProps={{
                      className: styles['information-text-error']
                    }}
                    inputProps={{
                      className: `${inputProps.className}`,
                      ...params.inputProps
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </ClickAwayListener>
      ) : (
        <span
          className={`${
            isReadOnly
              ? styles['information-date-input-text-element-read-only']
              : styles['information-date-input-text-element']
          } ${!value ? styles['information-date-input-text-element-no-data'] : ''}`}
          onClick={handleClickInput}>
          {value || 'No data'}
        </span>
      )}
    </div>
  );
};

export default InformationDateInput;
