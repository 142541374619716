import React from 'react';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { PERIOD_1_YEAR } from 'components/features/statistics/time-period/TimePeriodSelection';
import CompaniesStatisticListSimple from 'components/features/statistics/display-companies/CompaniesStatisticListSimple';
import styles from './_about.module.css';
import CopyToClipboardButton from 'components/shared/buttons/copy-to-clipboard-button/CopyToClipboardButton';

const About = () => {
  return (
    <div className={styles['about-main']}>
      <div className={styles['about-logo-wrapper']}>
        <Logo height={90} width={240} />
      </div>
      <p className={styles['about-version-text']}>Version: 1.0.0</p>
      <p className={styles['about-text']}>
        Neuron is the productivity platform that empowers Softhouse Sales to understand competence
        needs, make leads search and competence matching faster.
      </p>
      <p className={styles['about-text']}>
        If you signup to automatic leads postings via email, please use the address below
      </p>
      <div className={styles['about-mail']}>
        <CopyToClipboardButton text="stream.autoingest@softhouse.se" />
      </div>
      <div className={styles['about-table']}>
        <CompaniesStatisticListSimple timePeriod={PERIOD_1_YEAR} />
      </div>
    </div>
  );
};

export default About;
