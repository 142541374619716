import * as TypesLeads from 'types/leads';

export function getTableData(
  leads: TypesLeads.Lead[],
  favorites: TypesLeads.Favorite[],
  userId?: number
): TypesLeads.Lead[] {
  return leads.map((lead: TypesLeads.Lead) => {
    const favorite = favorites.filter((favorite: TypesLeads.Favorite) => {
      if (favorite.leadId === lead.leadId && favorite.userId === userId) {
        return favorite;
      }
    })[0];

    return {
      ...lead,
      isFavorite: Boolean(favorite)
    };
  });
}

export function getFormattedCreateTime(createTimeOriginal: string): string {
  // Create a Date object for the current time
  const now = new Date();

  // Parse the lead's createTime string into a Date object
  const createTime = new Date(createTimeOriginal);

  // Calculate the difference in milliseconds
  const timeDiff = now.getTime() - createTime.getTime();

  // Check if the lead is more than a day old
  const leadIsMoreThanADayOld = timeDiff > 24 * 60 * 60 * 1000; // One day in milliseconds

  // Check if the lead is less than 5 minutes old
  const leadIsLessThan5MinutesOld = timeDiff < 5 * 60 * 1000; // Five minutes in milliseconds

  const year = createTime.getFullYear();
  const month = String(createTime.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
  const day = String(createTime.getDate()).padStart(2, '0');
  const hours = String(createTime.getHours()).padStart(2, '0');
  const minutes = String(createTime.getMinutes()).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}`;
  const formattedDateTimeWithHoursAndMinutes = `${year}-${month}-${day} ${hours}:${minutes}`;

  if (leadIsMoreThanADayOld) {
    return formattedDateTime;
  } else if (leadIsLessThan5MinutesOld) {
    return '< 5min';
  } else {
    return formattedDateTimeWithHoursAndMinutes;
  }
}
