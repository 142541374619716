import { Column, CellProps } from 'react-table';

import UserTableNameCell from './UserTableNameCell';
import UserTableRelevanceCell from './UserTableRelevanceCell';
import * as TypesLeads from 'types/leads';

export const Columns = (isForLead) =>
  [
    {
      Header: 'Name',
      id: 'firstName',
      accessor: (user: TypesLeads.User) => {
        return `${user.firstName} ${user.lastName}`;
      },
      Cell: (cell: CellProps<TypesLeads.User>) => <UserTableNameCell user={cell.row.original} />,
      width: isForLead ? 120 : 150
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: (user: TypesLeads.User) => user.location,
      Cell: (cell: CellProps<TypesLeads.User>) => (
        <div style={{ marginLeft: '50px' }}>{cell.row.original.location}</div>
      )
    },
    {
      Header: 'Assigned',
      id: 'assigned',
      accessor: (user: TypesLeads.User) => user.assigned,
      Cell: (cell: CellProps<TypesLeads.User>) => (
        <div style={{ textAlign: 'right', width: '100%' }}>{cell.row.original.assigned}%</div>
      )
    },
    {
      Header: 'Relevance',
      id: 'relevance',
      Cell: (cell: CellProps<TypesLeads.User>) => (
        <UserTableRelevanceCell user={cell.row.original} />
      )
    }
  ] as Column<TypesLeads.User>[];
