import React from 'react';

import { useGetAiCompareQuery } from 'redux/aiCompareApi';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';


function AiCompareResults({ leadId, userId } : { leadId: string, userId: number }) {

  const { data, isLoading } = useGetAiCompareQuery({ leadId: leadId, userId: userId });

  if (isLoading == true) {
    return (
      <LoadingThreeDots />
    )
  } else if ((data != null) && ('score' in data) && ('success' in data) && (data.success == true)) {
    return (
      <p>
        AI says { data.score }% match with lead!
      </p>
    )
  } else {
    return (
      <p>
        AI could not calculate match!
      </p>

  )}
}

export default AiCompareResults;