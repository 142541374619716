import { createAction, createReducer } from '@reduxjs/toolkit';

export interface FilterState {
  searchText: string;
  searchAnswered: string | null;
  searchResult: string | null;
  usersFilter: string;
  searchRelevant: string | null;
  searchFavorite: string | null;
  activeFilters: number;
  searchAssigned: string | null;
  leadsAge: number;
  leadsLocationFilter: string;
  consultantsLocationFilter: string;
  availabilityFilter: string;
  showSelectedUsersFilter: string;
  skillsFilter: string[];
}

export interface SetStatusAction {
  status: string;
  value: boolean | undefined;
}

export const SEARCH_ANSWERED_ANSWERED = 'ANSWERED';
export const SEARCH_ANSWERED_UNANSWERED = 'UNANSWERED';

export const SEARCH_RESULT_WON = 'WON';
export const SEARCH_RESULT_LOST = 'LOST';
export const SEARCH_RESULT_NO_RESULT = 'NO_RESULT';

export const SEARCH_RELEVANT = 'RELEVANT';
export const SEARCH_IRRELEVANT = 'IRRELEVANT';

export const SEARCH_FAVORITE = 'FAVORITE';
export const SEARCH_NOT_FAVORITE = 'NOT_FAVORITE';

export const SEARCH_ASSIGNED = 'ASSIGNED';
export const SEARCH_NOT_ASSIGNED = 'NOT_ASSIGNED';

const initialState = {
  searchText: '',
  searchAnswered: null,
  searchResult: null,
  usersFilter: '',
  searchRelevant: SEARCH_RELEVANT,
  searchFavorite: null,
  activeFilters: 1,
  searchAssigned: null,
  leadsAge: 7,
  leadsLocationFilter: '',
  consultantsLocationFilter: 'All',
  availabilityFilter: '0',
  showSelectedUsersFilter: 'All',
  skillsFilter: []
} as FilterState;

export const setSearchText = createAction<string>('filter/setSearchText');
export const toggleSearchStatus = createAction<string>('filter/toggleSearchStatus');
export const setUsersFilter = createAction<string>('filter/setUsersFilter');
export const toggleRelevantStatus = createAction<string>('filter/toggleRelevantStatus');
export const setLeadDateRange = createAction<number>('filter/setLeadDateRange');
export const setLeadsLocationFilter = createAction<string>('filter/setLeadsLocationFilter');
export const setConsultantsLocationFilter = createAction<string>(
  'filter/setConsultantsLocationFilter'
);
export const setAvailabilityFilter = createAction<string>('filter/setAvailabilityFilter');
export const setShowSelectedUserFilter = createAction<string>('filter/setShowSelectedUserFilter');
export const setSkillsFilter = createAction<string[]>('filter/setSkillsFilter');

export default createReducer(initialState, (builder) => {
  builder.addCase(setSearchText, (state, action) => {
    state.searchText = action.payload;
  });
  builder.addCase(setUsersFilter, (state, action) => {
    state.usersFilter = action.payload;
  });
  builder.addCase(setLeadDateRange, (state, action) => {
    state.leadsAge = action.payload;
  });
  builder.addCase(setLeadsLocationFilter, (state, action) => {
    state.leadsLocationFilter = action.payload;
  });
  builder.addCase(setConsultantsLocationFilter, (state, action) => {
    state.consultantsLocationFilter = action.payload;
  });
  builder.addCase(setAvailabilityFilter, (state, action) => {
    state.availabilityFilter = action.payload;
  });
  builder.addCase(setShowSelectedUserFilter, (state, action) => {
    state.showSelectedUsersFilter = action.payload;
  });
  builder.addCase(setSkillsFilter, (state, action) => {
    state.skillsFilter = action.payload;
  });

  builder.addCase(toggleSearchStatus, (state, action) => {
    switch (action.payload) {
      case SEARCH_ANSWERED_ANSWERED:
      case SEARCH_ANSWERED_UNANSWERED:
        if (state.searchAnswered === null && action.payload !== null) {
          state.activeFilters++;
        }
        if (state.searchAnswered == action.payload) {
          state.searchAnswered = null;
          state.activeFilters--;
          break;
        }
        state.searchAnswered = action.payload;
        break;
      case SEARCH_RESULT_NO_RESULT:
      case SEARCH_RESULT_WON:
      case SEARCH_RESULT_LOST:
        if (state.searchResult === null && action.payload !== null) {
          state.activeFilters++;
        }
        if (state.searchResult == action.payload) {
          state.searchResult = null;
          state.activeFilters--;
          break;
        }
        state.searchResult = action.payload;
        break;
      case SEARCH_RELEVANT:
      case SEARCH_IRRELEVANT:
        if (state.searchRelevant === null && action.payload !== null) {
          state.activeFilters++;
        }
        if (state.searchRelevant == action.payload) {
          state.searchRelevant = null;
          state.activeFilters--;
          break;
        }
        state.searchRelevant = action.payload;
        break;
      case SEARCH_FAVORITE:
      case SEARCH_NOT_FAVORITE:
        if (state.searchFavorite === null && action.payload !== null) {
          state.activeFilters++;
        }
        if (state.searchFavorite == action.payload) {
          state.searchFavorite = null;
          state.activeFilters--;
          break;
        }
        state.searchFavorite = action.payload;
        break;
      case SEARCH_ASSIGNED:
      case SEARCH_NOT_ASSIGNED:
        if (state.searchAssigned === null && action.payload !== null) {
          state.activeFilters++;
        }
        if (state.searchAssigned == action.payload) {
          state.searchAssigned = null;
          state.activeFilters--;
          break;
        }
        state.searchAssigned = action.payload;
        break;
      default:
        break;
    }
  });
});
