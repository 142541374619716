import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  KeyboardEvent,
  HTMLAttributes,
  useMemo
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  TextField
} from '@mui/material';
import { useSelector } from 'react-redux';

import * as TypesLeads from 'types/leads';
import { RootState } from 'redux/store';

import styles from './_assignPerson.module.css';
import DropDownArrow from '../../../../assets/icons/ArrowDropDownFilled.png';
import { getAssignedUserArray } from './SharedUtils';

interface Props {
  options: Array<TypesLeads.User>;
  isReadOnly?: boolean;
  onAssign: (arg0: number[] | undefined) => void;
  editableOnDefault?: boolean;
  assigned?: number[];
  share?: boolean;
}

const SalesPersonInput = (props: Props) => {
  const { assigned = [], options, editableOnDefault = false, isReadOnly, onAssign, share } = props;
  const [isEditing, setIsEditing] = useState<boolean>(editableOnDefault);
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { user: signedInUser } = useSelector((state: RootState) => state.auth);

  const userOptions: TypesLeads.User[] = useMemo(() => {
    const filteredOptions = options.filter(
      (user: TypesLeads.User) => user.userId !== signedInUser?.userId
    );

    const duplicates = filteredOptions.filter((item, index) =>
      filteredOptions.some(
        (otherItem, otherIndex) =>
          index !== otherIndex &&
          item.firstName === otherItem.firstName &&
          item.lastName === otherItem.lastName
      )
    );

    if (duplicates.length > 0) filteredOptions.splice(filteredOptions.indexOf(duplicates[0]), 1);

    if (signedInUser) filteredOptions.unshift(signedInUser);

    return share ? options : filteredOptions;
  }, [options]);

  const assignedUserArray: TypesLeads.User[] = useMemo(
    () => getAssignedUserArray(assigned, options),
    [options, assigned]
  );

  const handleOnAssign = (_, data: TypesLeads.User | null) => {
    if (data) {
      onAssign([data?.userId]);
      return;
    }
    onAssign([]);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') setIsEditing(false);
  };

  const handleClickInput = () => {
    if (!isReadOnly) setIsEditing(true);
  };

  useEffect(() => {
    if (wrapperRef.current) inputRef.current?.focus();
  }, [isEditing, isReadOnly]);

  useEffect(() => {
    setIsEditing(false);
  }, [assigned, isReadOnly]);

  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    user: TypesLeads.User,
    state: AutocompleteRenderOptionState
  ) => {
    if (state.index === 0 && state.inputValue === '' && share !== true) {
      return (
        <Box
          component="li"
          {...props}
          sx={{
            backgroundColor: 'var(--color-sh-blue) !important',
            fontSize: 'var(--font-size-s) !important',
            display: 'flex',
            flexDirection: 'row',
            gap: '.5rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'var(--color-white)',
            '&:hover': { backgroundColor: 'var(--color-sh-blue) !important' },
            '&:focus': { backgroundColor: 'var(--color-sh-blue) !important' }
          }}>
          <Avatar
            alt="user-avatar"
            src={user.imageUrl}
            className={styles['assign-person-avatar']}
          />
          Assign to me
        </Box>
      );
    }
    return (
      <Box
        component="li"
        {...props}
        sx={{
          fontSize: 'var(--font-size-s)',
          display: 'flex',
          flexDirection: 'row',
          gap: '.5rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}>
        <Avatar alt="user-avatar" src={user.imageUrl} className={styles['assign-person-avatar']} />
        {`${user.firstName} ${user.lastName}`}
      </Box>
    );
  };

  const renderOptionInput = useCallback(
    () => (
      <Autocomplete
        id="assign"
        open={isEditing}
        options={userOptions}
        getOptionLabel={(user: TypesLeads.User) => `${user?.firstName} ${user?.lastName}`}
        className={styles['assign-person-option-wrapper']}
        onBlur={() => setIsEditing(false)}
        onChange={handleOnAssign}
        defaultValue={assignedUserArray[0]}
        renderOption={renderOptions}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            onKeyDown={handleOnKeyDown}
            ref={wrapperRef}
            inputRef={inputRef}
            placeholder="Assign..."
            onBlur={() => setIsEditing(false)}
            className={styles['assign-person-option-textfield']}
            inputProps={{
              className: `${styles['assign-person-option-input']}`,
              style: { padding: 0, fontSize: 'var(--font-size-s)', paddingLeft: '.5rem' },
              ...params.inputProps
            }}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 'var(--font-size-s)', padding: '5px', paddingLeft: '.5rem' },
              startAdornment: (
                <Avatar
                  alt="user-avatar"
                  src={
                    options.find(
                      (user: TypesLeads.User) =>
                        `${user.firstName} ${user.lastName}` === params.inputProps.value
                    )?.imageUrl
                  }
                  className={styles['assign-person-avatar']}
                />
              )
            }}
          />
        )}
      />
    ),
    [isEditing]
  );

  return (
    <div className={`${styles['assign-person']}`}>
      {isEditing && !isReadOnly ? (
        renderOptionInput()
      ) : (
        <span
          className={`${isReadOnly
            ? styles['assign-person-text-element-read-only']
            : styles['assign-person-text-element']
            }
          `}
          onClick={handleClickInput}>
          {assignedUserArray.length > 0 ? (
            assignedUserArray.map((user: TypesLeads.User) => (
              <Box
                key={user.userId}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '.5rem',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <Avatar
                  src={user.imageUrl}
                  alt="user-avatar"
                  className={styles['assign-person-avatar']}
                />
                <span
                  style={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}>{`${user.firstName} ${user.lastName}`}</span>
                <img src={DropDownArrow} className={styles['assign-person-arrow']} />
              </Box>
            ))
          ) : (
            <div className={styles['assign-person-input-wrapper']}>
              <Avatar alt="user-avatar" className={styles['assign-person-avatar']} />
              Not assigned
              <img src={DropDownArrow} className={styles['assign-person-arrow']} />
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default SalesPersonInput;
