import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  KeyboardEvent,
  HTMLAttributes,
  useMemo
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  TextField
} from '@mui/material';

import * as TypesLeads from 'types/leads';

import styles from './_assignPerson.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DropDownArrow from '../../../../assets/icons/ArrowDropDownFilled.png';
import { getAssignedUserArray } from './SharedUtils';

interface Props {
  options: Array<TypesLeads.User>;
  isReadOnly?: boolean;
  onAssign: (arg0: number[] | undefined) => void;
  assigned?: number[];
  editableOnDefault?: boolean;
  userId?: number;
}

const ConsultInput = (props: Props) => {
  const { assigned = [], options, editableOnDefault = false, onAssign, isReadOnly, userId } = props;

  const [isEditing, setIsEditing] = useState<boolean>(editableOnDefault);
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const sortedOptions = useMemo(() => {
    const filteredUsers: TypesLeads.User[] = options.filter(
      (user: TypesLeads.User) => !assigned.includes(user.userId)
    );
    assigned.forEach((userId: number) => {
      const users: TypesLeads.User | undefined = options.find(
        (user: TypesLeads.User) => user.userId === userId
      );
      users && filteredUsers.unshift(users);
    });
    if (userId) {
      const userIndex = filteredUsers.map(e => e.userId).indexOf(userId || 0);
      const extractedUser = filteredUsers.splice(userIndex, 1)
      filteredUsers.unshift(extractedUser[0])
    }
    return filteredUsers;
  }, [assigned, options, isReadOnly]);

  const assignedUserArray: TypesLeads.User[] = useMemo(
    () => getAssignedUserArray(assigned, options),
    [sortedOptions, assigned, isReadOnly]
  );

  const assignedUsersLength = (): string => {
    return assignedUserArray.length - 1 > 0 ? `+${assignedUserArray.length - 1}` : '';
  };

  const handleOnAssign = (_, data: TypesLeads.User[] | null) => {
    const assignedArray: number[] = [];
    data?.forEach((user: TypesLeads.User) => assignedArray.push(user.userId));
    onAssign(assignedArray);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') setIsEditing(false);
  };

  const handleClickInput = () => {
    if (!isReadOnly) setIsEditing(true);
  };

  useEffect(() => {
    if (wrapperRef.current) inputRef.current?.focus();
  }, [isEditing, isReadOnly]);

  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    user: TypesLeads.User,
    state: AutocompleteRenderOptionState
  ) => {
    if (userId && state.index === 0 && state.inputValue === '' && assigned.length === 0) {
      return (
        <Box
          component="li"
          {...props}
          sx={{
            backgroundColor: 'var(--color-sh-blue) !important',
            fontSize: 'var(--font-size-s) !important',
            display: 'flex',
            flexDirection: 'row',
            gap: '.5rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'var(--color-white)',
            '&:hover': { backgroundColor: 'var(--color-sh-blue) !important' },
            '&:focus': { backgroundColor: 'var(--color-sh-blue) !important' }
          }}>
          <Avatar
            alt="user-avatar"
            src={user.imageUrl}
            className={styles['assign-person-avatar']}
          />
          {`Assign to ${user.firstName}`}
        </Box>
      );
    }
    return (
      <Box
        component="li"
        {...props}
        sx={{
          fontSize: 'var(--font-size-s) !important',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '.5rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}>
        <Avatar alt="user-avatar" src={user.imageUrl} className={styles['assign-person-avatar']} />
        <span
          style={{
            display: 'flex',
            flex: 1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}>{`${user.firstName} ${user.lastName}`}</span>
        {state.selected && (
          <CloseRoundedIcon sx={{ width: '16px', height: '16px', display: 'flex' }} />
        )}
      </Box>
    );
  };

  const renderOptionInput = useCallback(
    () => (
      <Autocomplete
        multiple={true}
        open
        id="assign"
        options={sortedOptions}
        getOptionLabel={(user: TypesLeads.User) => `${user.firstName} ${user.lastName}`}
        isOptionEqualToValue={(option: TypesLeads.User, value: TypesLeads.User) =>
          option.userId === value.userId
        }
        className={styles['assign-person-option-wrapper']}
        onBlur={() => setIsEditing(false)}
        onChange={handleOnAssign}
        disableClearable
        renderOption={renderOptions}
        disableCloseOnSelect
        defaultValue={assignedUserArray}
        renderTags={() => null}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            onKeyDown={handleOnKeyDown}
            ref={wrapperRef}
            placeholder="Assign..."
            inputRef={inputRef}
            onBlur={() => setIsEditing(false)}
            className={styles['assign-person-option-textfield']}
            inputProps={{
              className: `${styles['assign-person-option-input']}`,
              style: { padding: 0, fontSize: 'var(--font-size-s)', width: '60%' },
              ...params.inputProps
            }}
          />
        )}
      />
    ),
    [isEditing, isReadOnly]
  );

  return (
    <div className={`${styles['assign-consult']}`}>
      {isEditing && !isReadOnly ? (
        renderOptionInput()
      ) : (
        <span
          className={`${isReadOnly
            ? styles['assign-consult-text-element-read-only']
            : styles['assign-consult-text-element']
            }
        `}
          onClick={handleClickInput}>
          {assignedUserArray.length > 0 ? (
            assignedUserArray.map((user: TypesLeads.User) => (
              <Box
                key={user.userId}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '.5rem',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <Avatar
                  src={user.imageUrl}
                  alt="user-avatar"
                  className={styles['assign-person-avatar']}
                />
                <span
                  style={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}>{`${user.firstName} ${user.lastName}`}</span>
                <span>{assignedUsersLength()}</span>
                <img src={DropDownArrow} className={styles['assign-person-arrow']} />
              </Box>
            ))[0]
          ) : (
            <div className={styles['assign-person-input-wrapper']}>
              <Avatar alt="user-avatar" className={styles['assign-person-avatar']} />
              <span style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                Not assigned
              </span>
              <img src={DropDownArrow} className={styles['assign-person-arrow']} />
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default ConsultInput;
