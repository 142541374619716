import * as TypesLeads from 'types/leads';

export const getAssignedUserArray: (
  assigned: number[],
  options: Array<TypesLeads.User>
) => TypesLeads.User[] = (assigned: number[], options: Array<TypesLeads.User>) => {
  const assignedUsers: TypesLeads.User[] = [];
  assigned.forEach((userId: number) => {
    const user: TypesLeads.User | undefined = options.find(
      (user: TypesLeads.User) => user.userId === userId
    );
    user && assignedUsers.push(user);
  });
  return assignedUsers;
};
