import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import { useSearchParams } from 'react-router-dom';
import { useEditLeadMutation, useGetLeadQuery } from 'redux/leadApi';
import * as TypesLeads from 'types/leads';
import { getAssignedConsults, isUserAssigned } from './_functionsAssignUser';

interface Props {
  user?: TypesLeads.User;
}

const AssignUser = (props: Props) => {
  const { user } = props;
  const [searchParams] = useSearchParams();
  const leadId = String(searchParams.get('forLead'));
  const [editLead] = useEditLeadMutation();
  const { data: lead } = useGetLeadQuery(leadId);

  const userAssigned = isUserAssigned(lead, user);

  const handleOnClick = async () => {
    if (lead) {
      try {
        await editLead({
          leadId: lead.leadId,
          assignedConsultants: getAssignedConsults(lead, user)
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const displayAssignButton = useCallback(() => {
    return (
      <Button color={userAssigned ? 'primary' : 'secondary'} onClick={handleOnClick}>
        {userAssigned ? (
          <>
            <ButtonIcon>
              <DoneRoundedIcon />
            </ButtonIcon>
            Assigned to lead
          </>
        ) : (
          <>Assign to lead</>
        )}
      </Button>
    );
  }, [lead, user]);

  return displayAssignButton();
};

export default AssignUser;
