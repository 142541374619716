export interface Option {
  id: number | undefined;
  label: string | undefined;
  color: string;
}

export const getChosenOption = (option: Option): boolean | null | undefined => {
  if (option.id === 0) {
    return true;
  } else if (option.id === 1) {
    return false;
  } else if (option.id === 2) {
    return null;
  }
};
