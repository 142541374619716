import moment from 'moment';

import Box from 'components/layouts/box/Box';
import * as TypesLeads from 'types/leads';

import styles from './_projectData.module.css';
import FilledStarIcon from '@mui/icons-material/GradeRounded';

interface Props {
  lead?: TypesLeads.Lead;
  isLeadFavorite?: boolean;
}

function DataContent(props: Props) {
  const { lead, isLeadFavorite } = props;

  return (
    <Box small>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles['data-content-title-wrapper']}>
          <h3 className={styles['data-content-title']}>
            {isLeadFavorite && <FilledStarIcon style={{ marginRight: '5px' }} />}
            {`${lead?.role || 'N/A'} (${lead?.companyName})` || ''}
            {lead?.link && (<>
              <a href={lead?.link} target="_blank" rel="noreferrer noopener" style={{ marginLeft: '0.5rem' }}>
                {'Apply here'}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ height: '100%', marginLeft: '5px' }}>
                  <path
                    d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z"
                    fill="#334F68"
                  />
                </svg>
              </a>
            </>)}
          </h3>
        </div>
        <div className={styles['data-content-content']}>
          <div className={styles['data-content-info-field']}>
            <span className={styles['data-content-info-field-title']}>Last modified</span>
            <span className={styles['data-content-info-field-data']}>
              {moment.utc(lead?.updateTime).fromNow()}
            </span>
          </div>
          <div className={styles['data-content-info-field']}>
            <span className={styles['data-content-info-field-title']}>Created at</span>
            <span className={styles['data-content-info-field-data']}>
              {moment(lead?.createTime).format('YYYY MM DD')}
            </span>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default DataContent;
