import { Avatar, Box } from '@mui/material';
import React from 'react';
import * as TypesLeads from 'types/leads';
import styles from './_displayUsers.module.css';

interface Props {
  user: TypesLeads.User;
}

const UserTableNameCell = (props: Props) => {
  const { user } = props;
  return (
    <Box component="li" className={styles['users-table-row-cell-name']}>
      <Avatar className={styles['users-table-row-cell-name-image']} src={user.imageUrl} />
      <span style={{ fontWeight: '600' }}>{`${user.firstName} ${user.lastName}`}</span>
    </Box>
  );
};

export default UserTableNameCell;
