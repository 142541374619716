import { UserAbsence } from 'types/leads';

export interface AbsenceGraphEntry {
  start: number;
  end: number;
  extentPercentage: number;
}

export interface AbsenceGraphData {
  entries: AbsenceGraphEntry[];
  earliest: number;
  latest: number;
  length: number;
  rowStyle: (start: number, end: number) => { left: string; right: string };
}

export function getAvailabilitytGraphData(
  absences: UserAbsence[],
  leadStart?: number,
  leadEnd?: number
): AbsenceGraphData {
  const entries = absences
    .filter((absence: UserAbsence) => absence.startDate != null && absence.endDate != null)
    .map((absence) => ({
      start: Date.parse(absence.startDate ?? ''),
      end: Date.parse(absence.endDate ?? ''),
      extentPercentage: absence.extentPercentage
    }));

  let earliest = getEarliestDate(entries);
  let latest = getLatestDate(entries);

  if (leadStart == undefined) leadStart = Date.now();

  if (leadEnd == undefined) leadEnd = leadStart + 1000 * 60 * 60 * 24 * 30 * 6; // add 6 months

  earliest = Math.min(leadStart, earliest);
  latest = Math.max(leadEnd, latest);

  const length = latest - earliest;

  const left = (start: number) => `${((start - earliest) / length) * 100}%`;
  const right = (end: number) => `${((latest - end) / length) * 100}%`;

  return {
    entries,
    earliest,
    latest,
    length,
    rowStyle: (start: number, end: number) => ({
      left: left(start),
      right: right(end)
    })
  };
}

function getEarliestDate(entries: AbsenceGraphEntry[]) {
  return Math.min(...entries.map((entry: AbsenceGraphEntry) => entry.start));
}

function getLatestDate(entries: AbsenceGraphEntry[]) {
  return Math.max(...entries.map((entry: AbsenceGraphEntry) => entry.end));
}
