import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ErrorMessage from 'components/shared/error/ErrorMessage';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';

import { useGetTopCompaniesQuery } from 'redux/statisticsApi';
import * as TypesLeads from 'types/leads';

import styles from './_displayCompanies.module.css';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { TimePeriod } from '../time-period/TimePeriodSelection';

const displayTable = (topCompanies: TypesLeads.TopCompany[]) => {
  return (
    <Table>
      <TableHead>
        <TableRow className={styles['companies-stat-list-head-row']}>
          <TableCell className={styles['companies-stat-list-title']}>Company</TableCell>
          <TableCell className={styles['companies-stat-list-title']}>Roles</TableCell>
          <TableCell className={styles['companies-stat-list-title']} align="right">
            Lead Amount
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody className={styles['companies-stat-list-body']}>
        {topCompanies.map((company: TypesLeads.TopCompany) => {
          return (
            <TableRow key={company.companyName}>
              <TableCell>
                <div className={styles['companies-stat-list-company']}>{company.companyName}</div>
              </TableCell>
              <TableCell>
                <div className={styles['companies-stat-list-roles']}>
                  {company.roles.join(', ')}
                </div>
              </TableCell>
              <TableCell align="right">
                <div className={styles['companies-stat-list-amount']}>{company.leadAmount}</div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

interface Props {
  timePeriod: TimePeriod;
}

const CompaniesStatList = (props: Props) => {
  const { timePeriod } = props;
  /* Renderar om sidan då skärmens höjd förändras. */
  useWindowHeight();

  const { data, error } = useGetTopCompaniesQuery(timePeriod.query());

  const STATISTICS_HEADER_HEIGHT = 60 + 8;
  const STATISTICS_GRID_GAP = 10;
  const PAGE_VERTICAL_MARGIN = 20;
  const STATISTICS_PERIOD_HEIGHT = 30;
  const STATISTICS_CHARTS_HEIGHT = 0.35 * window.innerHeight;
  const STATISTICS_COMPANIES_HEADER_HEIGHT = 60;

  const TOP_COMPANIES_ROW_HEIGHT = 53;

  let rowCount = getRowCountForHeight(
    window.innerHeight -
      (STATISTICS_HEADER_HEIGHT +
        STATISTICS_GRID_GAP * 2 +
        STATISTICS_CHARTS_HEIGHT +
        PAGE_VERTICAL_MARGIN +
        STATISTICS_PERIOD_HEIGHT +
        STATISTICS_COMPANIES_HEADER_HEIGHT),
    TOP_COMPANIES_ROW_HEIGHT
  );

  rowCount = Math.max(0, rowCount);

  const rows = data?.slice(0, rowCount);

  return (
    <TableContainer component={Paper}>
      {(() => {
        if (error) {
          return <ErrorMessage />;
        }

        if (!rows) {
          return <LoadingThreeDots />;
        }

        return displayTable(rows);
      })()}
    </TableContainer>
  );
};

export default CompaniesStatList;
