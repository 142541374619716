import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    fullfilled: true;
    notFullfilled: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    form: true;
  }
}

const buttonBackgroundColor = '#FFFFFF';
const buttonDisabledBackgroundColor = '#c3c3c3';
const buttonBackgroundColorSecondary = '#ffffff';

export const theme = createTheme({
  typography: {
    fontFamily: 'MontserratRegular, Arial'
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'fullfilled' },
          style: {
            backgroundColor: '#127369'
          }
        },
        {
          props: { variant: 'notFullfilled' },
          style: {
            backgroundColor: '#D98162'
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.Mui-disabled': {
            backgroundColor: buttonDisabledBackgroundColor,
            border: `1px solid ${buttonDisabledBackgroundColor}`
          }
        }
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: buttonBackgroundColor,
            borderColor: '#37506A',
            borderWidth: '1px',
            borderStyle: 'solid',
            color: '#37506A',
            fontWeight: 700,
            transition: '300ms',
            '&:hover': {
              backgroundColor: buttonBackgroundColor,
              opacity: 0.7
            },
            '&:focus': {
              backgroundColor: buttonBackgroundColor,
              opacity: 0.7
            }
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            color: buttonBackgroundColor,
            backgroundColor: buttonBackgroundColorSecondary,
            border: `1px solid ${buttonBackgroundColor}`,
            fontWeight: 700,
            transition: '300ms',
            '&:hover': {
              backgroundColor: buttonBackgroundColor,
              color: buttonBackgroundColorSecondary
            },
            '&:focus': {
              backgroundColor: buttonBackgroundColor,
              color: buttonBackgroundColorSecondary
            }
          }
        },
        {
          props: { variant: 'form' },
          style: {
            backgroundColor: '#eeeeee',
            color: '#757575',
            minHeight: '30px',
            minWidth: '30px',
            padding: '0',
            '&:hover': {
              backgroundColor: '#e0e0e0'
            },
            '&:focus': {
              backgroundColor: '#e0e0e0'
            }
          }
        }
      ]
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          alignItems: 'center'
        },
        primary: {
          fontSize: '15px'
        },
        secondary: {
          fontsize: '13px'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          backgroundColor: '#5b7f72'
        },
        root: {
          backgroundColor: '#96a896'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '0.2rem 0.5rem;',
          backgroundColor: buttonBackgroundColorSecondary,
          color: '#37506a',
          fontSize: '0.8rem',
          transition: '300ms',
          flexGrow: 1,
          '&:hover': {
            backgroundColor: '#37506A',
            color: '#ffffff'
          },
          '&[aria-pressed=true]': {
            backgroundColor: '#37506A',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#37506A',
              color: '#ffffff'
            }
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'MontserratRegular';
            src: local('MontserratRegular'), local('Montserrat-Regular'), url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
          }
        `
    }
  }
});
