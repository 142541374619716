import LoginContent from 'components/features/login/LoginContent';

interface Props {
  redirectPath: string
}

export default function Login(props: Props) {
  const { redirectPath } = props;
  return <LoginContent redirectPath={redirectPath} />;
}
