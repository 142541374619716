import { Avatar, Tooltip } from '@mui/material';

import { useGetUserQuery } from 'redux/userApi';

interface Props {
  userId: number;
}

function CommentUserInformation(props: Props) {
  const { userId } = props;

  const { data: user } = useGetUserQuery(userId);

  return (
    <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
      <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user?.imageUrl} />
    </Tooltip>
  );
}

export default CommentUserInformation;
