export const getSkillLevelColor = (level: number): string => {
  switch (level) {
    case 0:
      return '#dcdcde';
    case 1:
      return '#ff8585';
    case 2:
      return '#ffd585';
    case 3:
      return '#fffa85';
    case 4:
      return '#d3ff85';
    case 5:
      return '#9ade85';
    default:
      return 'transparent';
  }
};
