import { ActionType, Row, TableInstance, TableState } from 'react-table';
import * as TypesLeads from 'types/leads';

import { matchSorter } from 'match-sorter';

export function getProgressValue(value?: number): number {
  return value ? value * 100 : 0;
}

function getCompetenceStatusForLead(
  leadId: string,
  usersWithCompetenceMatchForLead?: TypesLeads.CompetenceMatchForLead[]
): TypesLeads.CompetenceMatchForLead | undefined {
  return usersWithCompetenceMatchForLead?.find(
    (userWithCompetenceMatchForLead: TypesLeads.CompetenceMatchForLead) =>
      userWithCompetenceMatchForLead.leadId === leadId
  );
}

export function getLeadsWithCompetenceForUser(
  leads?: TypesLeads.Lead[],
  leadsWithCompetenceForUser?: TypesLeads.CompetenceMatchForLead[]
): TypesLeads.Lead[] {
  if (!leads?.length) {
    return [];
  }

  return leads.map((lead: TypesLeads.Lead) => {
    const competenceStatusForUser = getCompetenceStatusForLead(
      lead.leadId,
      leadsWithCompetenceForUser
    );

    if (competenceStatusForUser) {
      const competenceStatus = {
        leadId: competenceStatusForUser?.leadId,
        userId: competenceStatusForUser?.userId,
        weight: competenceStatusForUser?.weight,
        matchWeights: competenceStatusForUser?.matchWeights,
        absences: competenceStatusForUser?.absences
      };

      return {
        ...lead,
        competenceStatus: competenceStatus
      };
    }

    return lead;
  });
}

export const filterLeads = (leads: TypesLeads.Lead[]): TypesLeads.Lead[] => {
  return leads.filter((lead) => !lead.irrelevant?.value);
};

export const sortLeadsByMatch = (users: TypesLeads.Lead[]): TypesLeads.Lead[] => {
  let sortedArray: TypesLeads.Lead[] = [];

  sortedArray = users.sort((a: TypesLeads.Lead, b: TypesLeads.Lead) => {
    if (a.competenceStatus?.weight !== undefined && b.competenceStatus?.weight !== undefined) {
      if (a.competenceStatus?.weight < b.competenceStatus?.weight) {
        return 1;
      }
      if (a.competenceStatus?.weight > b.competenceStatus?.weight) {
        return -1;
      }
    }
    return 0;
  });
  return sortedArray || [];
};

export const tableReducer = (
  newState: TableState<TypesLeads.Lead>,
  action: ActionType,
  previousState: TableState<TypesLeads.Lead>,
  instance?: TableInstance<TypesLeads.Lead>
): TableState<TypesLeads.Lead> => {
  if (action.type === 'resetGlobalFilter') {
    newState.selectedRowIds = {
      ['0']: true
    };
  }
  if (action.type === 'setGlobalFilter') {
    const id = instance?.rows[0]?.id || '0';
    if (instance && !(instance?.rows.length === 0)) {
      newState.selectedRowIds = {
        [id]: true
      };
    } else {
      const id = instance?.selectedFlatRows[0]?.id || '0';
      newState.selectedRowIds = {
        [id]: false
      };
    }
  }
  if (action.type === 'toggleRowSelected') {
    newState.selectedRowIds = {
      [action.id]: true
    };
  }
  return newState;
};
