import type { ChartOptions } from 'chart.js';

export const titleColor = '#1b1b1b';
export const titleSize = 20;

export const topSkillsChartOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      beginAtZero: true
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        usePointStyle: true,
        boxHeight: 8
      }
    },
    title: {
      color: titleColor,
      font: {
        size: titleSize
      },
      display: false,
      text: 'Top requested skills'
    }
  }
};

export const monthlyLeadsChartOptions: ChartOptions<'bar'> = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      beginAtZero: true
    }
  },
  datasets: {
    bar: {
      barPercentage: 0.8,
      categoryPercentage: 0.8,
      minBarLength: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        usePointStyle: true,
        boxHeight: 8
      }
    },
    title: {
      color: titleColor,
      font: {
        size: titleSize
      },
      display: false,
      text: 'Monthly total leads'
    }
  }
};
