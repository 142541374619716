import { FormControl } from '@mui/material';
import { array, object, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, Resolver, useForm } from 'react-hook-form';

import FormActionBar from '../form-actions/FormActionBar';
import FormActionButton from '../form-actions/FormActionButton';
import SalesPersonInput from './SalesPersonInput';
import ConsultInput from './ConsultInput';

import { useGetAllUsersQuery } from 'redux/userApi';
import { useEditLeadMutation } from 'redux/leadApi';

import * as TypesLeads from 'types/leads';

import styles from './_assignPerson.module.css';

interface Props {
  lead: TypesLeads.Lead;
  isReadOnly: boolean;
}
interface LeadAssign {
  assignedSalespersons?: number[];
  assignedConsultants?: number[];
}

const AssignPersonForm = (props: Props) => {
  const { lead, isReadOnly } = props;
  const { data = [] } = useGetAllUsersQuery();
  const [editLead] = useEditLeadMutation();
  const userId = JSON.parse(localStorage.getItem('user') || "").userId

  const assignSchema = object().shape({
    assignedSalespersons: array().of(number()),
    assignedConsultants: array().of(number())
  });

  const defaultAssign = {
    assignedSalespersons: lead.assignedSalespersons,
    assignedConsultants: lead.assignedConsultants
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm<LeadAssign>({
    resolver: yupResolver(assignSchema) as Resolver<LeadAssign, any>,
    defaultValues: defaultAssign
  });

  const handleReset = () => {
    reset(defaultAssign);
  };

  const handleUpdate = async (assignStatus: LeadAssign) => {
    await editLead({
      leadId: lead.leadId,
      assignedConsultants: assignStatus?.assignedConsultants,
      assignedSalespersons: assignStatus?.assignedSalespersons
    }).unwrap();
    reset(assignStatus);
  };

  return (
    <>
      <FormActionBar title="Assign">
        {isDirty && (
          <>
            <FormActionButton variant="clear" onClick={handleReset} />
            <FormActionButton variant="save" onClick={handleSubmit(handleUpdate)} />
          </>
        )}
      </FormActionBar>

      <FormControl fullWidth className={styles['assign-person-form']}>
        <div className={styles['assign-person-form-row']}>
          <span className={styles['assign-person-form-row-label']}>Salesperson</span>
          <Controller
            control={control}
            name="assignedSalespersons"
            render={({ field }) => (
              <SalesPersonInput
                isReadOnly={isReadOnly}
                options={data}
                assigned={field.value}
                onAssign={(data: (number | undefined)[] | undefined) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </div>
        <div className={styles['assign-consult-form-row']}>
          <span className={styles['assign-consult-form-row-label']}>Consultant(s)</span>
          <Controller
            control={control}
            name="assignedConsultants"
            render={({ field }) => (
              <ConsultInput
                isReadOnly={isReadOnly}
                options={data}
                assigned={field.value}
                onAssign={(data: (number | undefined)[] | undefined) => {
                  field.onChange(data);
                }}
                userId={userId}
              />
            )}
          />
        </div>
      </FormControl>
    </>
  );
};

export default AssignPersonForm;
