import React from 'react';
import { LinearProgress } from '@mui/material';
import styles from '../../../leads-for-user/_leadsForUser.module.css';

type Props = {
  value: number;
};

const ConsultantSkillProgress = (props: Props) => {
  const { value } = props;

  return (
    <LinearProgress
      variant="determinate"
      className={styles['user-table-relevance-cell-progress-bar']}
      color="secondary"
      sx={{
        backgroundColor: '#FBFBFB',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#399620'
        }
      }}
      value={value}
    />
  );
};

export default ConsultantSkillProgress;
