import React, { ReactNode, SyntheticEvent } from 'react';

import FormActionButton from 'components/features/lead-details/form-actions/FormActionButton';

import styles from './_chip.module.css';
import styled from '@emotion/styled';
import { BadgeProps, Badge } from '@mui/material';
import { getSkillLevelColor } from '../skills-level-badge/_functionsSkillsLevel';

interface ChipProps {
  text: string;
  level?: number;
  weight?: number;
  deletable?: boolean;
  onDelete?: (event: SyntheticEvent) => void;
}

interface StyledSkillsLevelBadgeProps extends BadgeProps {
  level: number;
}

const StyledSkillsLevelBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'level'
})<StyledSkillsLevelBadgeProps>(({ level }) => ({
  '& .MuiBadge-badge': {
    color: '#333333',
    border: '1px solid #333333',
    backgroundColor: getSkillLevelColor(level),
    fontWeight: '700',
    fontFamily: 'OpenSansRegular'
  }
}));

const Chip = (props: ChipProps) => {
  const { text, level, weight, deletable = false, onDelete } = props;
  return (
    <div className={`${styles['chip']} ${weight ? weight > 6 ? styles['chip-required'] : '' : ''}`}>
      <div
        className={`${styles['chip-text']} ${weight ? weight > 6 ? styles['chip-required-text'] : '' : ''} ${deletable ? styles['chip-text-delete'] : ''} ${level ? styles['chip-text-level'] : ''
          }`}
        style={{ fontFamily: 'OpenSansRegular' }}>
        {text}
      </div>
      {level !== undefined && (
        <div className={styles['chip-level']}>
          <StyledSkillsLevelBadge
            showZero={true}
            badgeContent={level === 0 ? '-' : level}
            level={level}
          />
        </div>
      )}
      {deletable && (
        <div className={styles['delete']}>
          {deletable && <FormActionButton variant="clear" onClick={onDelete && onDelete} />}
        </div>
      )}
    </div>
  );
};

export default Chip;

interface ChipContainerProps {
  children: ReactNode;
  isReadOnly: boolean;
  onClick?: () => void;
}

export const ChipContainer = (props: ChipContainerProps) => {
  const { children, isReadOnly, onClick } = props;

  return (
    <div
      className={`${isReadOnly ? styles['chips-container-read-only'] : styles['chips-container']}`}
      onClick={onClick && onClick}>
      {children}
    </div>
  );
};
