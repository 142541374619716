import styles from './_buttonIcon.module.css';

interface Props {
  children: string | JSX.Element | JSX.Element[];
  left?: boolean;
  small?: boolean;
}

function ButtonIcon(props: Props) {
  const { children, left, small = true } = props;

  const getClassName = (): string => {
    if (left && small) {
      return `${styles['button-icon']} ${styles['left']} ${styles['small']}`;
    }

    if (left) {
      return `${styles['button-icon']} ${styles['left']}`;
    }

    if (small) {
      return `${styles['button-icon']} ${styles['small']}`;
    }

    return styles['button-icon'];
  };

  return <div className={getClassName()}>{children}</div>;
}

export default ButtonIcon;
