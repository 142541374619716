import { useParams } from 'react-router-dom';

import PageLayout from 'components/layouts/PageLayout';

import { useGetAllLeadsQuery, useLazyGetFindCompetenceForUserQuery } from 'redux/leadApi';

import {
  filterLeads,
  getLeadsWithCompetenceForUser,
  sortLeadsByMatch
} from 'components/features/leads-for-user/_functionsDisplayLeads';

import * as TypesLeads from 'types/leads';
import LeadsForUserContent from 'components/features/leads-for-user/LeadsForUserContent';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';
import { useEffect } from 'react';

type Props = {
  filters: FilterState;
}

const LeadsForUser = (props: Props) => {
  const { filters } = props;
  const { userId } = useParams();

  const { data: leadsOriginal = [], isLoading, isError } = useGetAllLeadsQuery({ leadsAge: filters.leadsAge, leadsLocationFilter: filters.leadsLocationFilter });

  const [trigger, { data: userWithCompetenceForLeads }] = useLazyGetFindCompetenceForUserQuery();
  useEffect(() => {
    trigger(parseInt(userId ?? '0'))
  }, [userId])

  const leadsMerged: TypesLeads.Lead[] = getLeadsWithCompetenceForUser(
    leadsOriginal,
    userWithCompetenceForLeads?.matches
  );

  const leadsFiltered = filterLeads(leadsMerged);

  const leadsSortedByMatch = sortLeadsByMatch(leadsFiltered);

  if (!leadsOriginal) {
    return (
      <PageLayout>
        <span>No data found</span>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <LeadsForUserContent
        leads={leadsSortedByMatch}
        isLoading={isLoading}
        isError={isError}
        hiddenColumns={[]}
      />
    </PageLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(LeadsForUser);
