import React, { useState, useEffect } from 'react';

import { Button } from '@mui/material';

import styles from './AiCompare.module.css';

import AiCompareResults from './AiCompareResults';

interface Props {
  leadId: string;
  userId: number;
}

function AiCompareButton(props: Props) {

  const { leadId, userId } = props;

  const [buttonPressed, setButtonPressed] = useState(false);

  useEffect(() => {
    setButtonPressed(false);
  }, [userId]);

  return (
    !buttonPressed ? (
      <Button onClick={() => setButtonPressed(true)} className={styles['ai-compare-button']} >
        Run AI Compare
      </Button>
    ) : (
      <Button onClick={() => null} className={styles['ai-compare-button']}>
        <AiCompareResults leadId={leadId} userId={userId} />
      </Button>
    )
  )
}

export default AiCompareButton;