import { neuronApi } from './api';

import * as TypesLeads from 'types/leads';
import * as TypesRedux from 'types/redux';

const statisticsApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getFavorites: builder.query<TypesLeads.Favorite[], void>({
      query: () => ({
        url: '/favorites',
        method: 'GET'
      }),
      providesTags: ['Favorites']
    }),
    addFavorite: builder.mutation<void, TypesRedux.AddToFavoriteRequest>({
      query: ({ leadId }) => ({
        url: `/favorites/add/${leadId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Favorites']
    }),
    removeFavorite: builder.mutation<void, TypesRedux.RemoveFromFavoritesRequest>({
      query: ({ leadId }) => ({
        url: `/favorites/remove/${leadId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Favorites']
    })
  }),
  overrideExisting: false
});

export const { useGetFavoritesQuery, useAddFavoriteMutation, useRemoveFavoriteMutation } =
  statisticsApiSlice;
