import Chip from 'components/shared/chip/Chip';
import React from 'react';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';
import ViewAllLess from 'components/shared/view-all-less/ViewAllLess';

interface Props<T> {
  skills?: TypesLeads.Tag[];
  shouldDisplayAll: boolean;
  onClick: () => void;
  isTextOverLimit?: boolean;
  isText?: boolean;
  limitNumber?: number;
  data?: T[];
}

function SkillsList<T>(props: Props<T>) {
  const { skills, shouldDisplayAll, limitNumber, data, onClick } = props;

  return (
    <div className={styles['chip-container']}>
      {skills?.length !== 0 ? (
        skills &&
        skills.map((skill: TypesLeads.Tag, index: number) => {
          return index == skills.length - 1 ?
            <React.Fragment key={index}>
              <Chip text={skill.tagName} level={skill.level} weight={skill.leadTagWeight} />
              <ViewAllLess
                shouldDisplayAll={shouldDisplayAll}
                limitNumber={limitNumber}
                data={data as TypesLeads.Tag[]}
                onClick={onClick}
              />
            </React.Fragment>
            :
            <Chip key={index} text={skill.tagName} level={skill.level} weight={skill.leadTagWeight} />
        })
      ) : (
        <span className={styles['users-table-container-information-body-section-no-data']}>
          No data
        </span>
      )}
    </div>
  );
}

export default SkillsList;
