import { ChartData } from 'chart.js';

import * as TypesLeads from 'types/leads';

const lineColors: string[] = ['#0875B6', '#1EAE7B', '#4B00E9', '#DC8045'];

export const generateLeadsStatsData = (
  leadFrequencies: TypesLeads.LeadFrequencies
): ChartData<'bar'> => {
  const chartData: ChartData<'bar'> = {
    labels: leadFrequencies?.labels,
    datasets: [
      {
        label: 'Total leads',
        data: leadFrequencies?.leadAmounts,
        backgroundColor: '#127369'
      },
      {
        label: 'Answered',
        data: leadFrequencies?.answeredAmounts,
        backgroundColor: '#0268C8'
      },
      {
        label: 'Won',
        data: leadFrequencies?.wonAmounts,
        backgroundColor: '#399620'
      },
      {
        label: 'Lost',
        data: leadFrequencies?.lostAmounts,
        backgroundColor: '#D01A31'
      }
    ]
  };

  return chartData;
};

export const generateTopSkillsData = (
  leadFrequencies: TypesLeads.LeadFrequencies,
  numberOfSkills: number
): ChartData<'line'> => {
  const chartData: ChartData<'line'> = {
    labels: leadFrequencies?.labels,
    datasets: []
  };

  leadFrequencies?.data.forEach((tag: TypesLeads.TagFrequency, index: number) => {
    if (index < numberOfSkills) {
      chartData.datasets.push({
        label: tag.label,
        data: tag.data,
        fill: false,
        borderColor: lineColors[index],
        backgroundColor: lineColors[index],
        tension: 0.1
      });
    }
  });

  return chartData;
};
