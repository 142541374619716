import React from 'react';
import styles from './_leadToast.module.css';

type Props = {
  text: string;
  data?: { cancelDelete: boolean };
};

const LeadToast = (props: Props) => {
  const { text, data } = props;

  const handleClick = () => {
    data!.cancelDelete = true;
  };

  return (
    <div className={styles['lead-toast-wrapper']}>
      <p>{text}</p>
      {data && (
        <p className={styles['lead-toast-undo-button']} onClick={handleClick}>
          Undo
        </p>
      )}
    </div>
  );
};

export default LeadToast;
