import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Column,
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { Paper, TableContainer } from '@mui/material';
import sort from '../../../../assets/icons/sort.png';
import sortdesc from '../../../../assets/icons/sortdesc.png';
import sortasc from '../../../../assets/icons/sortasc.png';

import { Columns } from './Columns';
import UsersTable from './UsersTable';
import UserOverview from './UserOverview';
import ActionBar from '../action-bar/ActionBar';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import { FilterState } from 'redux/filterReducer';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';
import { getAllLocations, getAllSkills, handleFiltering } from './TableFilterUtils';
import useRowSelectionConsultants from './UseRowSelectionConsultants';
import ConsultantLeadsOverview from './display-users-multiple-matching-leads/ConsultantLeadsOverview';
import { RootState } from 'redux/store';
import { setConsultantPageState, setConsultantIsSwitched } from 'redux/navigationReducer';

export const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 60;

interface Props {
  isError?: boolean;
  isLoading: boolean;
  users: TypesLeads.User[];
  filters: FilterState;
  matchingLoaded: boolean;
  hiddenColumns: string[];
  returnToMultipleSelected: boolean;
}

function UsersContent(props: Props) {
  const { users, isError, isLoading, filters, matchingLoaded, hiddenColumns, returnToMultipleSelected } = props;
  const { consultantsLocationFilter, showSelectedUsersFilter, skillsFilter, usersFilter } = filters;
  const locationObject = useLocation();
  const [data, setData] = useState(users);
  const isForLead = location.href.split('?')[1]?.includes('forLead');
  const columns = useMemo(() => Columns(isForLead) as Column<TypesLeads.User>[], [isForLead]);
  const dispatch = useDispatch();
  const { consultantPageState } = useSelector((state: RootState) => state.navigation);
  const [allLocations, setAllLocations] = useState<string[]>([]);
  const [allSkills, setAllSkills] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<TypesLeads.User>(users[users.findIndex(p => p.userId == locationObject.state?.userId)]);
  const [isLeadsView, setIsLeadsView] = useState<boolean>(
    !returnToMultipleSelected ? returnToMultipleSelected : consultantPageState.matchingLeadsMultipleToggle
  );
  const [activeCheckedUserIndex, setActiveCheckedUserIndex] = useState(-1)
  const [myLeads, setMyLeads] = useState(locationObject.state?.myLeads)

  /* Renderar om sidan då skärmens höjd förändras. */
  useWindowHeight();

  const {
    selectedFlatRows,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: consultantPageState.selectedUserIds as Record<string, boolean>
      },
      autoResetPage: false,
      autoResetSelectedRows: false,
      getRowId: (row) => String(row.userId)
    },
    useFlexLayout,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowSelectionConsultants()
  );

  useEffect(() => {
    if (!isLoading) setData(users);
  }, [isLoading, isForLead, users.length]);

  useEffect(() => {
    const selectedUserIdFromLocalStorage = localStorage.getItem('selectedUserId') || '0'

    if (selectedUserIdFromLocalStorage) {
      const convertedSelectedUserFromLocalStorage = Number(selectedUserIdFromLocalStorage)
      if (convertedSelectedUserFromLocalStorage !== selectedUser?.userId) {
        dispatch(setConsultantIsSwitched(true))
        localStorage.setItem('matchingLeadsTableIndex', String(0))
        localStorage.setItem('selectedUserId', selectedUser?.userId.toString())
      } else {
        dispatch(setConsultantIsSwitched(false))
      }
    }
  }, [selectedUser])

  useEffect(() => {
    setData(
      handleFiltering(showSelectedUsersFilter, selectedFlatRows, selectedRowIds, users, consultantsLocationFilter, usersFilter, skillsFilter)
    );
  }, [showSelectedUsersFilter, consultantsLocationFilter, usersFilter, skillsFilter]);

  useEffect(() => {
    if (users.length) {
      setAllLocations(getAllLocations(users));
      setAllSkills(getAllSkills(users));
      setSelectedUser(users[0]);
    }
  }, [users]);

  useEffect(() => {
    selectedUserPage();
  }, [location]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(Math.max(0, pageCount - 1));
    }
    selectedUserPage();
  }, [pageCount]);

  const selectedUserPage = () => {
    if (locationObject.state?.userId) {
      const userIndex = users.map((e) => e.userId).indexOf(locationObject.state?.userId);
      setSelectedUser(users[userIndex]);
      gotoPage(Math.floor(userIndex / pageSize));
    }
  };

  const handleTableHeight = (element: HTMLDivElement) => {
    if (element?.clientHeight) {
      /* Remove some height for table header and footer*/
      let rowCount = getRowCountForHeight(
        element.clientHeight - (USERS_TABLE_FOOTER_HEIGHT + USERS_TABLE_HEAD_HEIGHT),
        USERS_TABLE_ROW_HEIGHT
      );

      rowCount = Math.max(1, rowCount);

      rowCount = Math.max(1, rowCount);

      if (pageSize != rowCount) {
        setPageSize(rowCount);
      }
    }
  };

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    // selectedFlatRows.length < 1 && setIsLeadsView(false);
  }, [selectedFlatRows]);

  useEffect(() => {
    dispatch(
      setConsultantPageState({
        selectedUserIds: selectedRowIds,
        matchingLeadsMultipleToggle: false
      })
    );
  }, [selectedRowIds, isLeadsView]);

  const paginationData: TypesLeads.Pagination = {
    pageIndex: pageIndex,
    pageOptionsLength: pageOptions.length,
    nextPage: nextPage,
    previousPage: previousPage,
    gotoPage: gotoPage,
    pageCount: pageCount,
    canNextPage: canNextPage,
    canPreviousPage: canPreviousPage
  };

  const showSortingArrow = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) return sortdesc;
      return sortasc;
    }
    return sort;
  };

  useEffect(() => {
    if (selectedFlatRows.length > 1 && activeCheckedUserIndex !== -1) {
      const test = selectedFlatRows[activeCheckedUserIndex]?.original;
      setSelectedUser(test);
    }

  }, [activeCheckedUserIndex]);

  useEffect(() => {
    if (locationObject.state?.myLeads) {
      const userIndex = users.findIndex(item => item.userId === locationObject.state?.userId)
      setSelectedUser(users[userIndex]);
      const userPageIndex = Math.floor(userIndex / pageSize);
      gotoPage(userPageIndex);
      setMyLeads(true);
      setIsLeadsView(true)
    }
  }, [locationObject])

  return (
    <div className={styles['users-page-container']}>
      <ActionBar
        allLocations={allLocations}
        allSkills={allSkills}
        isLeadsView={isLeadsView}
        setIsLeadsView={setIsLeadsView}
        selectedFlatRows={selectedFlatRows}
        isForLead={isForLead}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={styles['users-table-action-bar-wrapper']}>
          <TableContainer
            component={Paper}
            className={
              isLeadsView ? styles['users-table-container-fixed'] : styles['users-table-container']
            }
            ref={handleTableHeight}>
            <div className={styles['users-table-head']}>
              {headerGroups.map((headerGroup: HeaderGroup<TypesLeads.User>, index: number) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                  className={styles['users-table-head-row']}>
                  {headerGroup.headers.map((column: HeaderGroup<TypesLeads.User>) => {
                    if (column.id === 'selection')
                      return (
                        <div key={column.id} style={{ width: '40px', paddingLeft: '9px' }}>
                          {column.render('Header')}
                        </div>
                      );
                    return (
                      <div
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={column.id}
                        className={
                          column.Header === 'Name'
                            ? styles['users-table-head-cell-name']
                            : column.Header === 'Assigned'
                              ? styles['users-table-head-cell-assigned']
                              : styles['users-table-head-cell']
                        }>
                        {column.render('Header')}
                        {
                          column.canSort && <img
                            src={showSortingArrow(column)}
                            className={styles['users-table-head-cell-sorting-arrow']}
                          />
                        }

                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <UsersTable
              isError={isError}
              isLoading={isLoading}
              rows={page}
              totalUsers={data.length}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              isFilter={filters.usersFilter.length > 0}
              paginationData={paginationData}
              pageSize={pageSize}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              setActiveCheckedUserIndex={setActiveCheckedUserIndex}
            />
          </TableContainer>
        </div>
        {isLeadsView ? (
          <ConsultantLeadsOverview users={selectedFlatRows} singleUser={selectedUser} />
        ) : (
          <UserOverview user={selectedUser} isLeadsView={isLeadsView} isLoading={isLoading} matchingLoaded={matchingLoaded} isForLead={isForLead} hasReturned={locationObject.state?.hasReturned} myLeads={myLeads} userId={locationObject.state?.userId} selectedFlatRows={selectedFlatRows} activeCheckedUserIndex={activeCheckedUserIndex} setActiveCheckedUserIndex={setActiveCheckedUserIndex} />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(UsersContent);
