import React, {
  useRef,
  SyntheticEvent,
  useCallback,
  useState,
  useEffect,
  HTMLAttributes
} from 'react';
import {
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps
} from '@mui/material';
import Chip, { ChipContainer } from 'components/shared/chip/Chip';

import ViewAllLess from 'components/shared/view-all-less/ViewAllLess';

import { useGetAllTagsQuery } from 'redux/leadApi';
import { getLimitedList } from 'components/shared/view-all-less/_functionsViewAllLess';
import * as TypesLeads from 'types/leads';

import styles from './_skills.module.css';

interface Props {
  skills?: TypesLeads.Tag[];
  isReadOnly: boolean;
  onChange: (tags: TypesLeads.Tag[]) => void;
}

const SkillsInput = (props: Props) => {
  const { skills, isReadOnly, onChange } = props;
  const { data = [], isLoading } = useGetAllTagsQuery();
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const [shouldDisplayAll, setShouldDisplayAll] = useState<boolean>(false);
  const DEFAULT_SKILLS_NUMBER = 15;

  const handleOnChange = (event: SyntheticEvent<Element, Event>, values: TypesLeads.Tag[]) => {
    onChange(values);
  };

  const handleClickInput = () => {
    if (!isReadOnly) {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    setShouldDisplayAll(false);
  }, [skills]);

  useEffect(() => {
    if (wrapperRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditing, isReadOnly]);

  const displayTag = (tag: TypesLeads.Tag, index: number) => {
    return <Chip key={index} text={tag.tagName} weight={tag.leadTagWeight} />;
  };

  const limitedSkills = getLimitedList(shouldDisplayAll, DEFAULT_SKILLS_NUMBER, skills).map(
    (tag: TypesLeads.Tag, index: number) => displayTag(tag, index)
  );

  const displayInput = useCallback(() => {
    return (
      <Autocomplete
        multiple
        id="tags-filled"
        loading={isLoading}
        options={data}
        filterSelectedOptions
        disableClearable
        getOptionLabel={(option: TypesLeads.Tag) => option.tagName}
        isOptionEqualToValue={(option: TypesLeads.Tag, value: TypesLeads.Tag) =>
          option.tagId === value.tagId
        }
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: TypesLeads.Tag) => {
          return (
            <li {...props} key={option.tagId}>
              {option.tagName}
            </li>
          );
        }}
        ref={wrapperRef}
        onBlur={() => setIsEditing(false)}
        onChange={handleOnChange}
        className={styles['skills-autocomplete']}
        renderTags={(
          value: readonly TypesLeads.Tag[],
          getTagProps: AutocompleteRenderGetTagProps
        ) =>
          value.map((option: TypesLeads.Tag, index: number) => (

            <Chip
              text={option.tagName}
              deletable={true}
              {...getTagProps({ index })}
              key={option.tagId || index}
            />
          ))
        }
        defaultValue={skills}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            multiline={false}
            placeholder="Edit skills..."
            className={styles['skills-textfield']}
            inputRef={inputRef}
            inputProps={{
              style: { fontSize: 'var(font-size-s)' },
              ...params.inputProps
            }}
            onKeyDown={(event) => {
              if (event.key === "Escape") setIsEditing(false)
            }}
          />
        )}
      />
    );
  }, [isEditing, isReadOnly]);

  return (
    <div className={styles['skills-input']}>
      {isEditing && !isReadOnly ? (
        displayInput()
      ) : (
        <>
          <ChipContainer isReadOnly={isReadOnly} onClick={handleClickInput}>
            {[...limitedSkills, <span key={limitedSkills.length + 1} className={styles['skills-input-add-skill-label']}>Edit skills</span>]}
          </ChipContainer>

          <ViewAllLess
            shouldDisplayAll={shouldDisplayAll}
            limitNumber={DEFAULT_SKILLS_NUMBER}
            data={skills as TypesLeads.Tag[]}
            onClick={() => setShouldDisplayAll(!shouldDisplayAll)}
          />
        </>
      )}
    </div>
  );
};

export default SkillsInput;
