import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';

import Pagination from 'components/shared/pagination/Pagination';

import * as TypesLeads from 'types/leads';

import styles from './_singleUserLeads.module.css';
import { useNavigate } from 'react-router-dom';
import { useGetAllLeadsQuery, useLazyGetFindCompetenceForUserQuery } from 'redux/leadApi';
import {
  filterLeads,
  getLeadsWithCompetenceForUser,
  getProgressValue,
  sortLeadsByMatch
} from 'components/features/leads-for-user/_functionsDisplayLeads';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import {
  leadsTableReducer,
  getLeadsTablePageSize,
  getCellTooltipText
} from 'components/features/leads/leads-table/_functionsDisplayLeads';
import {
  useTable,
  usePagination,
  HeaderGroup,
  Row,
  Cell,
  CellProps,
  Column
} from 'react-table';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';
import { setConsultantIsSwitched } from 'redux/navigationReducer';

const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 116;
interface Props {
  user: TypesLeads.User;
  filters: FilterState;
}

function UserLeads(props: Props) {
  useWindowHeight();
  const { user, filters } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { consultantIsSwitched } = useSelector((state: RootState) => state.navigation);
  const { data: leadsOriginal = [] } = useGetAllLeadsQuery({ leadsAge: filters.leadsAge, leadsLocationFilter: filters.leadsLocationFilter });
  const [trigger, { data: userWithCompetenceForLeads, isFetching, }] = useLazyGetFindCompetenceForUserQuery();
  const [isLeadsWithCompetenceLoaded, setIsLeadsWithCompetenceLoaded] = useState(false);
  useEffect(() => { trigger(user.userId ?? 0); setIsLeadsWithCompetenceLoaded(true); }, [user, filters.consultantsLocationFilter, filters.leadsAge]);
  const leadsMerged: TypesLeads.Lead[] = getLeadsWithCompetenceForUser(
    leadsOriginal,
    userWithCompetenceForLeads?.matches
  );
  const leadsFiltered = filterLeads(leadsMerged);
  const leadsSortedByMatch = sortLeadsByMatch(leadsFiltered);

  const data = useMemo(() => leadsSortedByMatch as TypesLeads.Lead[], [isFetching]);

  const columns = useMemo<Column<TypesLeads.Lead>[]>(
    () => [
      {
        accessor: 'companyName',
        Header: 'Consultant Broker'
      },
      {
        accessor: 'role',
        Header: 'Role',
      },
      {
        accessor: (info) => info.competenceStatus?.matchWeights?.tag,
        Header: 'Skills',
        Cell(info: CellProps<TypesLeads.Lead>) {
          return (
            <LinearProgress
              variant="determinate"
              className={styles['user-matching-leads-table-relevance-cell-progress-bar']}
              color="secondary"
              sx={{
                backgroundColor: '#FBFBFB',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#399620'
                }
              }}
              value={getProgressValue(info?.row?.original?.competenceStatus?.matchWeights?.tag)}
            />
          );
        }
      },
      {
        accessor: (info) => info.competenceStatus?.matchWeights?.availability,
        Header: 'Availability',
        Cell(info: CellProps<TypesLeads.Lead>) {
          return (
            <LinearProgress
              variant="determinate"
              className={styles['user-matching-leads-table-relevance-cell-progress-bar']}
              color="secondary"
              sx={{
                backgroundColor: '#FBFBFB',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#399620'
                }
              }}
              value={getProgressValue(
                info?.row?.original?.competenceStatus?.matchWeights?.availability
              )}
            />
          );
        }
      },
      {
        accessor: (info) => info.competenceStatus?.matchWeights?.location,
        Header: 'Location',
        Cell(info: CellProps<TypesLeads.Lead>) {
          return (
            <LinearProgress
              variant="determinate"
              className={styles['user-matching-leads-table-relevance-cell-progress-bar']}
              color="secondary"
              sx={{
                backgroundColor: '#FBFBFB',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#399620'
                }
              }}
              value={getProgressValue(
                info?.row?.original?.competenceStatus?.matchWeights?.location
              )}
            />
          );
        }
      },
      {
        accessor: 'leadId',
        Cell: () => (
          <div className={styles['user-matching-leads-row-button']}>
            <p className={styles['user-matching-leads-row-button-text']}>View Lead</p>
            <ChevronRightRoundedIcon className={styles['user-matching-leads-row-button-chevron']} />
          </div>
        )
      }
    ],
    []
  );

  const windowHeight = useWindowHeight();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<TypesLeads.Lead>(
    {
      columns,
      data: data,
      stateReducer: leadsTableReducer,
      initialState: {
        pageIndex: consultantIsSwitched ? 0 : Number(localStorage.getItem('matchingLeadsTableIndex')),
        pageSize: getLeadsTablePageSize(windowHeight)
      },
      autoResetPage: false
    },
    usePagination
  );

  const paginationData: TypesLeads.Pagination = {
    pageIndex: pageIndex,
    pageOptionsLength: pageOptions.length,
    nextPage: nextPage,
    previousPage: previousPage,
    gotoPage: gotoPage,
    pageCount: pageCount,
    canNextPage: canNextPage,
    canPreviousPage: canPreviousPage
  };

  const handleTableHeight = (element: HTMLDivElement) => {
    if (element?.clientHeight) {
      /* Remove some height for table header and footer*/
      let rowCount = getRowCountForHeight(
        element.clientHeight - (USERS_TABLE_FOOTER_HEIGHT + USERS_TABLE_HEAD_HEIGHT),
        USERS_TABLE_ROW_HEIGHT
      );

      rowCount = Math.max(1, rowCount);

      if (pageSize != rowCount) {
        setPageSize(rowCount);
      }
    }
  };

  const handleOnClick = (row) => {
    navigate(`/leads/${row.original.leadId}`, {
      state: {
        returnLocation: 'singleConsultant',
        firstName: user?.firstName,
        userId: user?.userId
      }
    });
  };

  useEffect(() => {
    setPageSize(getLeadsTablePageSize(windowHeight));
  }, [windowHeight]);

  useEffect(() => {
    localStorage.setItem('matchingLeadsTableIndex', String(pageIndex))
    dispatch(setConsultantIsSwitched(false))
  }, [pageIndex])

  useEffect(() => {
    consultantIsSwitched ? gotoPage(0) : null;
  }, [consultantIsSwitched])

  return (
    <>
      {!isFetching && isLeadsWithCompetenceLoaded && (
        <TableContainer
          component={Paper}
          className={styles['user-matching-leads-table-container']}
          ref={handleTableHeight}>
          <Table {...getTableProps()} className={styles['user-matching-leads-table']}>
            <TableHead className={styles['user-matching-leads-table-head']}>
              {headerGroups.map((headerGroup: HeaderGroup<TypesLeads.Lead>, index: number) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column: HeaderGroup<TypesLeads.Lead>) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      key={column.id}
                      className={styles['user-matching-table-head-cell']}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} className={styles['user-matching-table-body']}>
              {page.map((row: Row<TypesLeads.Lead>, index) => {
                prepareRow(row);
                row.getRowProps();
                return (
                  <TableRow key={index} className={styles['user-matching-leads-table-row']}>
                    {row.cells.map((cell: Cell<TypesLeads.Lead>) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          className={styles['user-matching-leads-table-row-cell']}
                          title={getCellTooltipText(cell)}
                          onClick={() => handleOnClick(row)}>
                          <Box className={styles['user-matching-leads-table-row-cell-content']}>
                            {cell.render('Cell')}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Table>
            <TableFooter className={styles['user-matching-leads-table-footer']}>
              <TableRow>
                <TableCell className={styles['user-matching-leads-table-pagination']}>
                  <Pagination
                    pageIndex={paginationData.pageIndex}
                    pageOptionsLength={paginationData.pageOptionsLength}
                    nextPage={paginationData.nextPage}
                    previousPage={paginationData.previousPage}
                    gotoPage={paginationData.gotoPage}
                    pageCount={paginationData.pageCount}
                    canNextPage={paginationData.canNextPage}
                    canPreviousPage={paginationData.canPreviousPage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      {isFetching && <LoadingThreeDots />}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(UserLeads);
