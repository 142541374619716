import { Dispatch, SetStateAction } from 'react';
import { Row, TablePropGetter } from 'react-table';
import { Table, TableRow, TableCell, TableBody, TableBodyProps, TableFooter } from '@mui/material';

import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';
import Pagination from 'components/shared/pagination/Pagination';
import { USERS_TABLE_ROW_HEIGHT } from './UsersContent';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';
import UsersTableRow from './UsersTableRow';

interface Props {
  isError?: boolean;
  isLoading: boolean;
  rows: Row<TypesLeads.User>[];
  totalUsers: number;
  getTableProps: () => TablePropGetter<TypesLeads.User>;
  getTableBodyProps: () => TableBodyProps;
  prepareRow: (row: Row<TypesLeads.User>) => void;
  isFilter: boolean;
  paginationData: TypesLeads.Pagination;
  pageSize: number;
  setSelectedUser: Dispatch<SetStateAction<TypesLeads.User>>;
  selectedUser: TypesLeads.User;
  setActiveCheckedUserIndex: (value: number) => void
}

export interface RowInformation {
  firstRowNbr: number;
  lastRowNbr: number;
  totalRowsNbr: number;
}

const UsersTable = (props: Props) => {
  const {
    isLoading,
    isError,
    rows,
    totalUsers,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    isFilter,
    paginationData,
    pageSize,
    setSelectedUser,
    selectedUser,
    setActiveCheckedUserIndex
  } = props;

  const pageIndex = paginationData.pageIndex;
  const rowsPerPage = rows.length;
  const firstRowNbr = pageIndex === 0 ? 1 : (pageIndex * rowsPerPage) + 1;
  const lastRowNbr = (pageIndex + 1) * rowsPerPage;
  const totalRowsNbr = totalUsers;
  const usersDisplayedInfo: RowInformation = { firstRowNbr, lastRowNbr, totalRowsNbr };

  return (
    <Table {...getTableProps()} className={styles['users-table']}>
      <TableBody
        {...getTableBodyProps()}
        className={styles['users-table-body']}
        style={{ height: `${pageSize * USERS_TABLE_ROW_HEIGHT}px` }}>
        {isLoading && isError && (
          <TableRow>
            <TableCell className={styles['users-table-row-cell-error']}>
              <ErrorMessage />
            </TableCell>
          </TableRow>
        )}
        {isFilter && rows.length === 0 && (
          <TableRow>
            <TableCell className={styles['users-table-row-cell-error']}>No matches found</TableCell>
          </TableRow>
        )}
        {isLoading && !isError ? (
          <TableRow>
            <TableCell className={styles['users-table-row-cell-loading']}>
              <LoadingThreeDots left />
            </TableCell>
          </TableRow>
        ) : (
          rows.map((row: Row<TypesLeads.User>) => {
            prepareRow(row);
            row.getRowProps();
            return (
              <UsersTableRow
                key={row.id}
                row={row}
                isSelected={row.isSelected}
                cells={row.cells}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                setActiveCheckedUserIndex={setActiveCheckedUserIndex}
              />
            );
          })
        )}
      </TableBody>

      <TableFooter className={styles['users-table-footer']}>
        <TableRow>
          <TableCell className={styles['users-table-pagination']}>
            <Pagination
              pageIndex={paginationData.pageIndex}
              pageOptionsLength={paginationData.pageOptionsLength}
              nextPage={paginationData.nextPage}
              previousPage={paginationData.previousPage}
              gotoPage={paginationData.gotoPage}
              pageCount={paginationData.pageCount}
              rowInformation={usersDisplayedInfo}
              canNextPage={paginationData.canNextPage}
              canPreviousPage={paginationData.canPreviousPage}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default UsersTable;
