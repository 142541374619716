import { useState } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-table';
import moment from 'moment';
import {
  Button,
  ClickAwayListener,
  Fade,
  MenuItem,
  Paper,
  Popper,
  Select,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useEditMultipleLeadsMutation } from 'redux/leadApi';

import SearchBar from 'components/shared/search-bar/SearchBar';
import AddLead from './AddLead';
import CSVExporter from './CSVExporter';

import {
  FilterState,
  SEARCH_ANSWERED_ANSWERED,
  SEARCH_ANSWERED_UNANSWERED,
  SEARCH_RESULT_LOST,
  SEARCH_RESULT_NO_RESULT,
  SEARCH_RESULT_WON,
  SEARCH_RELEVANT,
  SEARCH_IRRELEVANT,
  SEARCH_FAVORITE,
  SEARCH_NOT_FAVORITE,
  SEARCH_ASSIGNED,
  SEARCH_NOT_ASSIGNED,
  setSearchText,
  toggleSearchStatus,
  setLeadDateRange,
  setLeadsLocationFilter,
  setConsultantsLocationFilter
} from 'redux/filterReducer';
import { AppDispatch, RootState } from 'redux/store';
import * as TypesLeads from 'types/leads';

import styles from './_actionBar.module.css';
import LeadAgeFilter from 'components/features/users/action-bar/LeadAgeFilter';

interface Props {
  filters: FilterState;
  setSearchText: (text: string) => void;
  toggleSearchStatus: (text: string) => void;
  selectedLeads: Row<TypesLeads.Lead>[];
  locations: string[];
  setLeadDateRange: (value: number) => void;
  setLeadsLocationFilter: (value: string) => void;
  setConsultantsLocationFilter: (value: string) => void;
}

const ActionBar = (props: Props) => {
  const { filters, setSearchText, toggleSearchStatus, selectedLeads, setLeadDateRange, setLeadsLocationFilter, setConsultantsLocationFilter } = props;
  const { searchAnswered, searchResult, searchRelevant, searchFavorite, searchAssigned, activeFilters, searchText } = filters;

  const [editLeadsAnchorElement, setEditLeadsAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [filterAnchorElement, setFilterAnchorElement] = useState<HTMLButtonElement | null>(null);

  const openEditLeads = Boolean(editLeadsAnchorElement)
  const openFilter = Boolean(filterAnchorElement);
  const [editMultipleLeads] = useEditMultipleLeadsMutation();

  const handleOnRelevantStatusChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    toggleSearchStatus(value ?? searchRelevant);
  };

  const handleOnAnsweredStatusChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    toggleSearchStatus(value ?? searchAnswered);
  };

  const handleOnResultStatusChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    toggleSearchStatus(value ?? searchResult);
  };

  const handleOnFavoriteChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    toggleSearchStatus(value ?? searchFavorite);
  };

  const handleOnAssignedChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    toggleSearchStatus(value ?? searchAssigned);
  };

  const handleClickEditLeads = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditLeadsAnchorElement(event.currentTarget);
  };

  const handleCloseEditLeads = () => {
    setEditLeadsAnchorElement(null);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorElement(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorElement(null);
  };

  const handleEditLead = (action: string) => {
    const mutatedLeads = selectedLeads.map(val => {
      return {
        leadId: val.original.leadId,
        irrelevant: {
          date: moment().format('YYYY-MM-DD HH:mm'),
          value: action == 'irrelevant' ? true : false
        }
      }
    })
    editMultipleLeads(mutatedLeads).unwrap();
    handleCloseEditLeads();
  }

  const handleLocationChange = (value: string) => {
    setLeadsLocationFilter(value)

    if (value == 'Kalmar' || value == 'Jönköping' || value == 'Växjö') {
      setConsultantsLocationFilter('Småland');
    } else if (value == 'Karlshamn') {
      setConsultantsLocationFilter('Karlskrona/Karlshamn')
    } else if (value === 'Remote') {
      setConsultantsLocationFilter('All');
    } else if (value == '') {
      setConsultantsLocationFilter('All')
    } else {
      setConsultantsLocationFilter(value)
    }
  }

  return (
    <div className={styles['action-bar']}>
      <div className={styles['action-bar-triggers']}>
        <div className={styles['action-bar-triggers-group']} style={{ maxWidth: '500px' }}>
          <AddLead />
          <Button onClick={handleClickEditLeads} disabled={selectedLeads.length <= 0} sx={{ marginLeft: '0.3rem' }}>Edit leads</Button>
        </div>

        <div className={styles['action-bar-triggers-group']} style={{ maxWidth: '800px' }}>
          <Button
            size="small"
            className={styles['action-bar-triggers-group-filter-button']}
            onClick={handleClickFilter}>
            <FilterAltRoundedIcon />
            <span>Filters</span>
            {activeFilters > 0 && (
              <span className={styles['action-bar-triggers-group-filter-button-chip']}>
                {activeFilters}
              </span>
            )}
          </Button>
          <Select
            className={styles['action-bar-date-range-select']}
            style={{ width: '250px' }}
            value={filters.leadsLocationFilter}
            onChange={element => handleLocationChange(element.target.value)}
            displayEmpty
          >
            <MenuItem value={''}>All locations</MenuItem>
            <MenuItem value={'Malmö'}>Malmö</MenuItem>
            <MenuItem value={'Karlskrona'}>Karlskrona</MenuItem>
            <MenuItem value={'Växjö'}>Växjö</MenuItem>
            <MenuItem value={'Karlshamn'}>Karlshamn</MenuItem>
            <MenuItem value={'Jönköping'}>Jönköping</MenuItem>
            <MenuItem value={'Stockholm'}>Stockholm</MenuItem>
            <MenuItem value={'Luleå'}>Luleå</MenuItem>
            <MenuItem value={'Uppsala'}>Uppsala</MenuItem>
            <MenuItem value={'Kalmar'}>Kalmar</MenuItem>
            <MenuItem value={'Sarajevo'}>Sarajevo</MenuItem>
            <MenuItem value={'Remote'}>Remote (Beta)</MenuItem>
          </Select>
          <LeadAgeFilter setLeadDateRange={setLeadDateRange} />
          <div style={{ backgroundColor: 'white', borderRadius: '4px', width: '100%' }}>
            <SearchBar
              value={searchText}
              onChange={(newValue: string) => {
                setSearchText(newValue);
              }}
              label="Search leads"
            />
          </div>

        </div>
      </div>

      <Popper open={openEditLeads} anchorEl={editLeadsAnchorElement} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseEditLeads}>
                <div className={styles['action-bar-edit-leads-popper']}>
                  <Button onClick={() => handleEditLead('relevant')} className={styles['action-bar-edit-leads-popper-button']}>Relevant</Button>
                  <Button onClick={() => handleEditLead('irrelevant')} className={styles['action-bar-edit-leads-popper-button']}>Irrelevant</Button>
                  <CSVExporter selectedLeads={selectedLeads} />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Popper open={openFilter} anchorEl={filterAnchorElement} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseFilter}>
                <div className={styles['action-bar-toggle-groups']}>
                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={searchAnswered}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnAnsweredStatusChange}>
                    <ToggleButton value={SEARCH_ANSWERED_ANSWERED}>Answered</ToggleButton>
                    <ToggleButton value={SEARCH_ANSWERED_UNANSWERED}>Unanswered</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={searchResult}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnResultStatusChange}>
                    <ToggleButton value={SEARCH_RESULT_WON}>Won</ToggleButton>
                    <ToggleButton value={SEARCH_RESULT_LOST}>Lost</ToggleButton>
                    <ToggleButton value={SEARCH_RESULT_NO_RESULT}>No Result</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={searchRelevant}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnRelevantStatusChange}>
                    <ToggleButton value={SEARCH_RELEVANT}>Relevant</ToggleButton>
                    <ToggleButton value={SEARCH_IRRELEVANT}>Irrelevant</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={searchFavorite}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnFavoriteChange}>
                    <ToggleButton value={SEARCH_FAVORITE}>Favorite</ToggleButton>
                    <ToggleButton value={SEARCH_NOT_FAVORITE}>Not favorite</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={searchAssigned}
                    onChange={handleOnAssignedChange}>
                    <ToggleButton value={SEARCH_ASSIGNED}>Assigned To</ToggleButton>
                    <ToggleButton value={SEARCH_NOT_ASSIGNED}>Not Assigned</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSearchText: (value: string) => dispatch(setSearchText(value)),
  toggleSearchStatus: (value: string) => dispatch(toggleSearchStatus(value)),
  setLeadDateRange: (value: number) => dispatch(setLeadDateRange(value)),
  setLeadsLocationFilter: (value: string) => dispatch(setLeadsLocationFilter(value)),
  setConsultantsLocationFilter: (value: string) => dispatch(setConsultantsLocationFilter(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
