import { Bar } from 'react-chartjs-2';
import { monthlyLeadsChartOptions as options } from 'constants/chartJS';

import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';

import { useGetLeadFrequenciesQuery } from 'redux/statisticsApi';
import { generateLeadsStatsData } from './_functionsCharts';
import { TimePeriod } from '../time-period/TimePeriodSelection';

interface Props {
  timePeriod: TimePeriod;
}

const LeadsBarChart = (props: Props) => {
  const { timePeriod } = props;

  const { data, error } = useGetLeadFrequenciesQuery(timePeriod.query());

  if (error) {
    return <ErrorMessage center />;
  }

  if (!data) {
    return <LoadingThreeDots />;
  }

  return <Bar options={options} data={generateLeadsStatsData(data)} />;
};

export default LeadsBarChart;
