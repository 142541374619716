import { neuronApi } from './api';

import * as TypesLeads from 'types/leads';

const userApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<TypesLeads.User[], void>({
      query: () => ({
        url: 'users/',
        method: 'GET'
      }),
      transformResponse: (
        response: TypesLeads.User[]
      ): TypesLeads.User[] | Promise<TypesLeads.User[]> => {
        return response.sort((a: TypesLeads.User, b: TypesLeads.User) =>
          new Intl.Collator('sv').compare(a.firstName, b.firstName)
        );
      }
    }),
    getUser: builder.query<TypesLeads.User, number | void>({
      query: (id: number) => ({
        url: `users/${id}`,
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useGetAllUsersQuery, useGetUserQuery } = userApiSlice;
