import { Box } from '@mui/material';
import React from 'react';
import * as TypesLeads from 'types/leads';
import styles from './_leadsForUser.module.css';

interface Props {
  lead: TypesLeads.Lead;
}

const UserTableNameCell = (props: Props) => {
  const { lead } = props;
  return (
    <Box component="li" className={styles['users-table-row-cell-name']}>
      <span>{`${lead.role ?? ""}`}</span>
    </Box>
  );
};

export default UserTableNameCell;
