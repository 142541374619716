import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, TextField } from '@mui/material';

import CommentsContent from './CommentsContent';
import FormActionButton from '../form-actions/FormActionButton';
import ViewAllLess from 'components/shared/view-all-less/ViewAllLess';

import { useAddCommentMutation, useGetAllCommentsQuery } from 'redux/commentApi';
import { RootState } from 'redux/store';
import { getLimitedList } from 'components/shared/view-all-less/_functionsViewAllLess';
import * as TypesLeads from 'types/leads';

import styles from './_comments.module.css';

interface Props {
  leadId: string;
  isReadOnly: boolean;
}

function Comments(props: Props) {
  const { leadId, isReadOnly } = props;

  const [isEditing, setIsEditing] = useState(false);
  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);
  const [addComment] = useAddCommentMutation();

  const DEFAULT_COMMENTS_NUMBER = 3;
  const { data: comments } = useGetAllCommentsQuery(leadId);
  const [shouldDisplayAllComments, setShouldDisplayAllComments] = useState<boolean>(false);
  const limitedComments = getLimitedList<TypesLeads.Comment>(
    shouldDisplayAllComments,
    DEFAULT_COMMENTS_NUMBER,
    comments
  );

  const formSchema = object({
    commentContent: string().optional()
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      commentContent: ''
    }
  });

  const handleAddComment = async (value: { commentContent: string|undefined }): Promise<void> => {
    if (!loggedInUser?.userId) {
      return;
    }

    try {
      await addComment({
        leadId: leadId,
        body: {
          commentContent: value.commentContent ?? ""
        }
      }).unwrap();

      reset({ commentContent: '' });
      setIsEditing(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleClickText = (): void => {
    setIsEditing(true);
  };

  const handleReset = (): void => {
    reset({ commentContent: '' });
    setIsEditing(false);
  };

  return (
    <div className={styles['comments']}>
      <h3 className={styles['comments-title']}>Comments</h3>

      <div>
        {!isReadOnly &&
          (isEditing ? (
            <FormControl className={styles['comments-form']}>
              <Controller
                control={control}
                name="commentContent"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className={styles['comments-form-text-input']}
                    label="Comment here"
                    size="small"
                    autoComplete="off"
                    multiline
                    autoFocus
                    maxRows={3}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              {isDirty && (
                <div className={styles['comments-form-action']}>
                  <FormActionButton variant="clear" onClick={handleReset} />
                  <FormActionButton variant="save" onClick={handleSubmit(handleAddComment)} />
                </div>
              )}
            </FormControl>
          ) : (
            <div className={styles['comments-form-readonly']} onClick={handleClickText}>
              Comment here
            </div>
          ))}

        <CommentsContent leadId={leadId} comments={limitedComments} />

        <ViewAllLess
          shouldDisplayAll={shouldDisplayAllComments}
          limitNumber={DEFAULT_COMMENTS_NUMBER}
          data={comments as TypesLeads.Comment[]}
          onClick={() => setShouldDisplayAllComments(!shouldDisplayAllComments)}
        />
      </div>
    </div>
  );
}

export default Comments;
