import { useState } from 'react';
import InformationForm from 'components/features/lead-details/information/InformationForm';
import AssignPersonForm from 'components/features/lead-details/assign-person/AssignPersonForm';
import SkillsForm from 'components/features/lead-details/skills/SkillsForm';
import StatusForm from 'components/features/lead-details/status/StatusForm';
import Box from 'components/layouts/box/Box';
import LeadHeader from './LeadHeader';
import DescriptionContent from '../lead-details/description/DescriptionContent';
import DataContent from '../lead-details/project-data/DataContent';
import Comments from '../lead-details/comments/Comments';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';

import * as TypesLeads from 'types/leads';

import styles from './_lead.module.css';

interface Props {
  lead?: TypesLeads.Lead;
  isReadOnly: boolean;
  isLoading: boolean;
}

function LeadContent(props: Props) {
  const [isLeadFavorite, setIsLeadFavorite] = useState(false)
  const { lead, isReadOnly, isLoading } = props;

  return isLoading ? (
    <LoadingThreeDots fullScreen />
  ) : (
    <>
      <LeadHeader lead={lead} setIsLeadFavorite={setIsLeadFavorite} isLeadFavorite={isLeadFavorite} />

      <DataContent lead={lead} isLeadFavorite={isLeadFavorite} />

      <div className={styles['lead-content']}>
        <div className={styles['lead-content-left']}>
          <div className={styles['lead-content-left-boxes']}>
            <Box>
              {lead && <InformationForm lead={lead} isReadOnly={isReadOnly} />}
            </Box>

            <Box>
              <div className={styles['lead-content-status']}>
                {lead && <StatusForm lead={lead} isReadOnly={isReadOnly} />}
              </div>

              <div>{lead && <AssignPersonForm lead={lead} isReadOnly={isReadOnly} />}</div>
            </Box>
          </div>

          <Box>{lead && <Comments leadId={lead.leadId} isReadOnly={isReadOnly} />}</Box>
        </div>

        <div className={styles['lead-content-right']}>
          <Box>{lead && <SkillsForm lead={lead} isReadOnly={isReadOnly} />}</Box>
          <DescriptionContent lead={lead} />
        </div>
      </div>
    </>
  );
}

export default LeadContent;
