import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import styles from './_displayCompanies.module.css';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { useGetTopCompaniesQuery } from 'redux/statisticsApi';
import * as TypesLeads from 'types/leads';
import { TimePeriod } from '../time-period/TimePeriodSelection';
import ErrorMessage from 'components/shared/error/ErrorMessage';

type Props = {
  timePeriod: TimePeriod;
};

const CompaniesStatisticListSimple = (props: Props) => {
  const { timePeriod } = props;

  /* Renderar om sidan då skärmens höjd förändras. */
  useWindowHeight();

  const { data, error } = useGetTopCompaniesQuery(timePeriod.query());

  const STATISTICS_HEADER_HEIGHT = 60 + 8;
  const STATISTICS_GRID_GAP = 10;
  const PAGE_VERTICAL_MARGIN = 20;
  const STATISTICS_PERIOD_HEIGHT = 30;
  const STATISTICS_CHARTS_HEIGHT = 0.35 * window.innerHeight;
  const STATISTICS_COMPANIES_HEADER_HEIGHT = 60;

  const TOP_COMPANIES_ROW_HEIGHT = 53;

  let rowCount = getRowCountForHeight(
    window.innerHeight -
      (STATISTICS_HEADER_HEIGHT +
        STATISTICS_GRID_GAP * 2 +
        STATISTICS_CHARTS_HEIGHT +
        PAGE_VERTICAL_MARGIN +
        STATISTICS_PERIOD_HEIGHT +
        STATISTICS_COMPANIES_HEADER_HEIGHT),
    TOP_COMPANIES_ROW_HEIGHT
  );

  rowCount = Math.max(0, rowCount);

  const rows = data?.slice(0, rowCount) || [];

  return (
    <Table className={styles['companies-stat-simple-table']}>
      <TableHead>
        <TableRow className={styles['companies-stat-list-head-row']}>
          <TableCell
            className={styles['companies-stat-list-title']}
            sx={{
              borderRadius: '3px 0 0 0'
            }}>
            Company
          </TableCell>
          <TableCell
            className={styles['companies-stat-list-title']}
            sx={{
              borderRadius: '0 3px 0 0'
            }}
            align="right">
            Number of leads
          </TableCell>
        </TableRow>
      </TableHead>
      {error && <ErrorMessage />}

      {!error && (
        <TableBody className={styles['companies-stat-list-body']}>
          {rows.map((company: TypesLeads.TopCompany, index: number) => {
            return (
              <TableRow
                key={company.companyName}
                sx={{ backgroundColor: `${index % 2 === 0 ? '#f0f0f0' : 'white'}` }}>
                <TableCell>
                  <div className={styles['companies-stat-list-company']}>{company.companyName}</div>
                </TableCell>
                <TableCell align="right">
                  <div className={styles['companies-stat-list-amount']}>{company.leadAmount}</div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

export default CompaniesStatisticListSimple;
