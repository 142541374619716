import styles from './_errorMessage.module.css';

type Props = {
  fullScreen?: boolean;
  left?: boolean;
  center?: boolean;
};

const ErrorMessage = (props: Props) => {
  const { fullScreen, left, center } = props;

  return (
    <div
      className={`${styles['error']} ${fullScreen ? styles['error-fullscreen'] : ''} ${
        left ? styles['error-left'] : ''
      } ${center ? styles['error-center'] : ''}`}>
      Failed to fetch data
    </div>
  );
};

export default ErrorMessage;
