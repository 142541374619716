import styles from './_layouts.module.css';

interface Props {
  children: string | JSX.Element | JSX.Element[];
}

function PageLayout(props: Props) {
  const { children } = props;

  return <div className={styles['page-layout']}>{children}</div>;
}

export default PageLayout;
