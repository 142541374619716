import * as TypesLeads from 'types/leads';

export function isFavorite(
  favorites?: TypesLeads.Favorite[],
  userId?: number,
  leadId?: string
): boolean {
  if (!favorites || !favorites.length) {
    return false;
  }

  const favorite = favorites.filter((favorite: TypesLeads.Favorite) => {
    if (favorite.leadId === leadId && favorite.userId === userId) {
      return favorite;
    }
  })[0];

  return favorites.includes(favorite);
}
