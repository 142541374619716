import { useEffect, useState } from 'react';
import { Row } from 'react-table';
import { Button } from '@mui/material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { ExportToCsv } from 'export-to-csv-fix-source-map';

import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';
import * as TypesLeads from 'types/leads';

import styles from './_actionBar.module.css';

interface Props {
  selectedLeads: Row<TypesLeads.Lead>[];
}

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};

const CSVExporter = (props: Props) => {
  const { selectedLeads } = props;

  const handleExport = () => {
    const exportCsv = new ExportToCsv(csvOptions);
    const items: TypesLeads.Lead[] = [];

    for (let index = 0; index < selectedLeads.length; index++) {
      const element = selectedLeads[index].original;
      items.push(element);
    }

    const csvData: TypesLeads.CSVData[] = items?.map((item: TypesLeads.Lead) => {
      return {
        ...item,
        tags: item.tags?.map((tag: TypesLeads.Tag) => tag.tagName).join(', '),
        assignedSalespersons: item.assignedSalespersons
          ?.map((userId?: number) => userId?.toString())
          .join(', '),
        assignedConsultants: item.assignedConsultants
          ?.map((userId?: number) => userId?.toString())
          .join(', '),
        persistant: item.persistant?.value ? 'YES' : 'NO',
        irrelevant: item.irrelevant?.value ? 'YES' : 'NO',
        result: item.result?.value != null ? (item.result?.value ? 'WON' : 'LOST') : '',
        answered: item.answered?.value ? 'YES' : 'NO'
      };
    });

    exportCsv.generateCsv(csvData);
  };

  return (
    <Button onClick={handleExport} className={styles['action-bar-edit-leads-popper-button']}>
      <ButtonIcon>
        <ExitToAppRoundedIcon />
      </ButtonIcon>
      Export to CSV
    </Button>
  );
};

export default CSVExporter;
