import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import CommentUserInformation from './CommentUserInformation';

import { useRemoveCommentMutation } from 'redux/commentApi';
import { RootState } from 'redux/store';
import { isCommentByLoggedInUser } from './_functionsComments';

import * as TypesLeads from 'types/leads';

import styles from './_comments.module.css';

interface Props {
  leadId: string;
  comments: TypesLeads.Comment[];
}

function CommentsContent(props: Props) {
  const { leadId, comments } = props;

  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);
  const [removeComment] = useRemoveCommentMutation();

  const handleRemoveComment = async (commentId: number): Promise<void> => {
    try {
      await removeComment({
        leadId: leadId,
        commentId: commentId
      }).unwrap();
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <>
      {comments.length > 0 ? (
        <div className={styles['comments-content-comments']}>
          {comments.map((comment: TypesLeads.Comment) => {
            return (
              <div key={comment.commentId} className={styles['comments-content-comment']}>
                <CommentUserInformation userId={comment.userId} />

                <div className={styles['comments-content-comment-text']}>
                  <span className={styles['comments-content-comment-text-date']}>
                    {comment.createTime}
                  </span>

                  <div className={styles['comments-content-comment-text-button']}>
                    <span>{comment.commentContent}</span>

                    {isCommentByLoggedInUser(comment.userId, loggedInUser?.userId) && (
                      <Button
                        variant="form"
                        className={styles['comments-content-comment-text-remove']}
                        onClick={() => handleRemoveComment(comment.commentId)}>
                        <CloseRoundedIcon
                          className={styles['comments-content-comment-text-remove-icon']}
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['comments-content-no-comments']}>No data</div>
      )}
    </>
  );
}

export default CommentsContent;
