import { useEffect, useState } from 'react';

function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', (event: Event) => {
      const target = event.target as Window;
      return setHeight(target.innerHeight);
    });

    return () =>
      window.removeEventListener('resize', (event: Event) => {
        const target = event.target as Window;
        return setHeight(target.innerHeight);
      });
  }, []);

  return height;
}

export default useWindowHeight;
