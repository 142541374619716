import React from 'react';
import styles from './_usersFilters.module.css';
import SearchBar from 'components/shared/search-bar/SearchBar';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';

interface Props {
  setUsersFilter: (val: string) => void;
  filters: FilterState;
}

const UsersFilters = (props: Props) => {
  const { setUsersFilter, filters } = props;

  return (
    <div className={styles['filters']}>
      <SearchBar value={filters.usersFilter} onChange={(value: string) => setUsersFilter(value)} label="Search consultants" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(UsersFilters);