import { Box, TableCell, TableRow } from '@mui/material';
import React, { MouseEvent, memo } from 'react';
import { Cell, Row } from 'react-table';

import * as TypesLeads from 'types/leads';

import styles from './_leadsForUser.module.css';

interface Props {
  row: Row<TypesLeads.Lead>;
  isSelected: boolean;
  cells: Cell<TypesLeads.Lead>[];
}

const LeadsForUserTableRow = memo((props: Props) => {
  const { row, isSelected } = props;

  const handleOnSelectRow = (
    event: MouseEvent<HTMLTableCellElement>,
    cell: Cell<TypesLeads.Lead>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    cell.row.toggleRowSelected();
  };

  return (
    <TableRow
      key={row.index}
      className={!isSelected ? styles['users-table-row'] : styles['users-table-row-selected']}>
      {row.cells.map((cell: Cell<TypesLeads.Lead>) => {
        return (
          <TableCell
            {...cell.getCellProps()}
            key={cell.column.id}
            title={`${row.original.role}`}
            className={styles['users-table-row-cell']}
            onClickCapture={(event: MouseEvent<HTMLTableCellElement>) =>
              handleOnSelectRow(event, cell)
            }>
            <Box component="div" className={styles['users-table-row-cell-box']}>
              {cell.render('Cell')}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
});

LeadsForUserTableRow.displayName = 'LeadsForUserTableRow';
export default LeadsForUserTableRow;
