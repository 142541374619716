import { useParams } from 'react-router-dom';

import PageLayout from 'components/layouts/PageLayout';
import LeadContent from 'components/features/lead-details/LeadContent';

import { useGetLeadQuery } from 'redux/leadApi';

const Lead = () => {
  const { leadId } = useParams();
  const { data: lead } = useGetLeadQuery(leadId ?? '');

  const isReadOnly = Boolean(lead?.irrelevant.value);

  return (
    <PageLayout>
      <LeadContent lead={lead} isReadOnly={isReadOnly} isLoading={!lead} />
    </PageLayout>
  );
};

export default Lead;
