import React from 'react';
import styles from './_linkToast.module.css';

interface Props {
  title: string;
  path: string;
}

const LinkToast = (props: Props) => {
  const { title, path } = props;

  return (
    <a className={styles['link-toast']} href={path}>
      {title}
    </a>
  );
};

const renderLinkToast = (title: string, path: string): JSX.Element => {
  return <LinkToast title={title} path={path} />;
};

export default renderLinkToast;
