import { Column, CellProps } from 'react-table';

import LeadsForUserTableNameCell from './LeadsForUserTableNameCell';
import LeadsForUserTableRelevanceCell from './LeadsForUserTableRelevanceCell';
import * as TypesLeads from 'types/leads';

export const Columns = [
  {
    Header: 'Role',
    id: 'role',
    accessor: (lead: TypesLeads.Lead) => {
      return `${lead.role}`;
    },
    Cell: (cell: CellProps<TypesLeads.Lead>) => (
      <LeadsForUserTableNameCell lead={cell.row.original} />
    )
  },
  {
    Header: 'Relevance',
    id: 'relevance',
    Cell: (cell: CellProps<TypesLeads.Lead>) => (
      <LeadsForUserTableRelevanceCell lead={cell.row.original} />
    )
  }
] as Column<TypesLeads.Lead>[];
