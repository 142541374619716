import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { FilterState } from 'redux/filterReducer';
import { RootState } from 'redux/store';

type Props = {
    filters: FilterState;
    setLeadDateRange: (value: number) => void;
};

const LeadAgeFilter = (props: Props) => {
    const { filters, setLeadDateRange } = props;


    return (
        <FormControl style={{ width: '30%' }}>
            <InputLabel id="leadAge">Lead Age</InputLabel>
            <Select
                labelId='leadAge'
                label='Lead Age'
                sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
                value={filters.leadsAge.toString()}
                onChange={(element => {setLeadDateRange(parseInt(element.target.value))})}
            >
                <MenuItem value={1}>1 day</MenuItem>
                <MenuItem value={7}>7 days</MenuItem>
                <MenuItem value={30}>30 days</MenuItem>
                <MenuItem value={0}>All</MenuItem>
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state: RootState) => ({
    filters: state.filters
});

export default connect(mapStateToProps)(LeadAgeFilter);