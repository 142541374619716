import { useNavigate } from 'react-router-dom';
import { Row, Cell } from 'react-table';
import { TableRow, TableCell, Box } from '@mui/material';

import * as TypesLeads from 'types/leads';

import styles from './_displayLeads.module.css';
import { getCellTooltipText } from './_functionsDisplayLeads';

interface Props {
  row: Row<TypesLeads.Lead>;
}

const LeadsTableRow = (props: Props) => {
  const { row } = props;
  const navigate = useNavigate();

  const handleOnClick = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    if (event.altKey) {
      window.open(`/leads/${row.original.leadId}`);
    } else if (event.shiftKey) {
      window.open(`/leads/${row.original.leadId}`);
    } else {
      navigate(`/leads/${row.original.leadId}`, { state: { returnLocation: 'leads' } });
    }
  };

  return (
    <>
      <TableRow
        className={`${styles['lead-table-row']} ${row.original.irrelevant.value ? styles['lead-table-row-irrelevant'] : ''
          } `}>
        {row.cells.map((cell: Cell<TypesLeads.Lead>) => {
          return (
            <TableCell
              {...cell.getCellProps()}
              key={cell.column.id}
              className={styles['lead-table-row-cell']}
              title={getCellTooltipText(cell)}
              onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => handleOnClick(event)}>
              <Box className={styles['lead-table-row-cell-content']}>{cell.render('Cell')}</Box>
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default LeadsTableRow;
