import React, { SyntheticEvent } from 'react';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@mui/material';

import styles from './_formActions.module.css';

interface Props {
  variant: 'save' | 'clear';
  onClick?: (event: SyntheticEvent) => void;
}

const FormActions = (props: Props) => {
  const { variant, onClick } = props;

  return (
    <Button variant="form" onClick={onClick} className={styles['form-action-button']}>
      {variant === 'save' ? (
        <DoneRoundedIcon className={styles['form-action-button-icon']} />
      ) : (
        <CloseRoundedIcon className={styles['form-action-button-icon']} />
      )}
    </Button>
  );
};

export default FormActions;
