import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { getWSUrl, useRefreshSessionQuery } from 'redux/api';

import Header from './header/Header';

import styles from './_layouts.module.css';
import renderLinkToast from 'components/shared/toasts/link-toast/LinkToast';
import { toast } from 'react-toastify';
import useWebSocket from 'react-use-websocket';
import { RootState } from 'redux/store';

const MainOutlet = () => {
  const { isLoading } = useRefreshSessionQuery();
  const { token, isLoggedIn } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const INCOMING_LEAD_TEXT = 'New incoming lead';

  const { lastMessage } = useWebSocket(
    getWSUrl(),
    {
      queryParams: {
        sessionId: token || 0
      }
    },
    isLoggedIn
  );

  useEffect(() => {
    const leadId = lastMessage?.data ? JSON.parse(lastMessage?.data).LeadId : null;
    const path = `${window.location.origin}/leads/${leadId}`;
    if (leadId) {
      lastMessage &&
        toast.info(renderLinkToast(INCOMING_LEAD_TEXT, path), {
          toastId: INCOMING_LEAD_TEXT,
          closeOnClick: false
        });
    }
  }, [lastMessage]);

  useEffect(() => {
    const path = localStorage.getItem('loggedOutPath');
    if (path) {
      navigate(path)
      localStorage.removeItem('loggedOutPath')
    }
  }, [])

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Header />
      <div className={styles['main-outlet']}>
        <Outlet />
      </div>
    </>
  );
};

export default MainOutlet;
