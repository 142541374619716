import { Box, TableCell, TableRow } from '@mui/material';
import React, { Dispatch, SetStateAction, memo } from 'react';
import { Cell, Row } from 'react-table';

import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';

interface Props {
  row: Row<TypesLeads.User>;
  isSelected: boolean;
  cells: Cell<TypesLeads.User>[];
  setSelectedUser: Dispatch<SetStateAction<TypesLeads.User>>;
  selectedUser: TypesLeads.User;
  setActiveCheckedUserIndex: (value: number) => void
}

const UsersTableRow = memo((props: Props) => {
  const { row, isSelected, setSelectedUser, selectedUser, setActiveCheckedUserIndex } = props;
  const isSingleSelected = row.original.cinodeUserId === selectedUser?.cinodeUserId;

  const handleClick = (user) => {
    setSelectedUser(user);
    setActiveCheckedUserIndex(-1)
  };

  const handleClassName = () => {
    return isSingleSelected
      ? styles['users-table-row-single-selected']
      : !isSelected
        ? styles['users-table-row']
        : styles['users-table-row-selected'];
  };

  return (
    <TableRow key={row.index} className={handleClassName()}>
      {row.cells.map((cell: Cell<TypesLeads.User>) => {
        if (cell.column.id === 'selection')
          return (
            <td key={cell.column.id} style={{ width: '40px' }}>
              {cell.render('Cell')}
            </td>
          );
        return (
          <TableCell
            {...cell.getCellProps()}
            key={cell.column.id}
            title={`${row.original.firstName} ${row.original.lastName}`}
            className={styles['users-table-row-cell']}
            onClickCapture={() => handleClick(row.original)}>
            <Box component="div" className={styles['users-table-row-cell-box']}>
              {cell.render('Cell')}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
});

UsersTableRow.displayName = 'UsersTableRow';
export default UsersTableRow;
