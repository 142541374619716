import { ActionType, Row, TableInstance, TableState } from 'react-table';
import * as TypesLeads from 'types/leads';

import { matchSorter } from 'match-sorter';

export function getProgressValue(value?: number): number {
  return value ? value * 100 : 0;
}

export const tableReducer = (
  newState: TableState<TypesLeads.User>,
  action: ActionType,
  previousState: TableState<TypesLeads.User>,
  instance?: TableInstance<TypesLeads.User>
): TableState<TypesLeads.User> => {
  if (action.type === 'resetGlobalFilter') {
    newState.selectedRowIds = {
      ['0']: true
    };
  }
  if (action.type === 'setGlobalFilter') {
    const id = instance?.rows[0]?.id || '0';
    if (instance && !(instance?.rows.length === 0)) {
      newState.selectedRowIds = {
        [id]: true
      };
    } else {
      const id = instance?.selectedFlatRows[0]?.id || '0';
      newState.selectedRowIds = {
        [id]: false
      };
    }
  }
  if (action.type === 'toggleRowSelected') {
    newState.selectedRowIds = {
      [action.id]: true
    };
  }
  return newState;
};

function getCompetenceStatusForUser(
  userId: number,
  usersWithCompetenceMatchForLead?: TypesLeads.CompetenceMatchForLead[]
): TypesLeads.CompetenceMatchForLead | undefined {
  return usersWithCompetenceMatchForLead?.find(
    (userWithCompetenceMatchForLead: TypesLeads.CompetenceMatchForLead) =>
      userWithCompetenceMatchForLead.userId === userId
  );
}

export function getUsersWithCompetenceForLead(
  users?: TypesLeads.User[],
  usersWithCompetenceForLead?: TypesLeads.CompetenceMatchForLead[]
): TypesLeads.User[] {
  if (!users?.length) {
    return [];
  }

  return users.map((user: TypesLeads.User) => {
    const competenceStatusForUser = getCompetenceStatusForUser(
      user.userId,
      usersWithCompetenceForLead
    );

    if (competenceStatusForUser) {
      const competenceStatus = {
        leadId: competenceStatusForUser?.leadId,
        weight: competenceStatusForUser?.weight,
        matchWeights: competenceStatusForUser?.matchWeights,
        absences: competenceStatusForUser?.absences
      };

      return {
        ...user,
        competenceStatus: competenceStatus
      };
    }

    return user;
  });
}

export const sortUsersByMatch = (users: TypesLeads.User[]): TypesLeads.User[] => {
  let sortedArray: TypesLeads.User[] = [];

  sortedArray = users.sort((a: TypesLeads.User, b: TypesLeads.User) => {
    if (a.competenceStatus?.weight !== undefined && b.competenceStatus?.weight !== undefined) {
      if (a.competenceStatus?.weight < b.competenceStatus?.weight) {
        return 1;
      }
      if (a.competenceStatus?.weight > b.competenceStatus?.weight) {
        return -1;
      }
    }
    return 0;
  });
  return sortedArray || [];
};

export const getMatchingSkills = (
  userSkills: TypesLeads.Tag[],
  leadSkills: TypesLeads.Tag[]
): TypesLeads.Tag[] => {
  const filteredSkills = userSkills.filter((userSkill: TypesLeads.Tag) =>
    leadSkills.some((leadSkill: TypesLeads.Tag) => leadSkill.tagId === userSkill.tagId)
  );
  const returnSkills = filteredSkills.map((filteredSkill: TypesLeads.Tag) => {
    const leadTagWeight =
      leadSkills[
        leadSkills.findIndex((leadSkill: TypesLeads.Tag) => leadSkill.tagId === filteredSkill.tagId)
      ].leadTagWeight;
    return { ...filteredSkill, leadTagWeight };
  });
  return returnSkills;
};

export const getMissingSkills = (
  leadsSkills: TypesLeads.Tag[],
  matchedSkills: TypesLeads.Tag[]
): TypesLeads.Tag[] => {
  return leadsSkills.filter(
    (leadSkill: TypesLeads.Tag) =>
      !matchedSkills.some((matchedSkill: TypesLeads.Tag) => leadSkill.tagId === matchedSkill.tagId)
  );
};

export const globalUsersFilter = (
  rows: Row<TypesLeads.User>[],
  columnIds: string[],
  filter: string
): Row<TypesLeads.User>[] => {
  if (filter.trim().length == 0) return rows;

  const filterValues = filter.split(' ');
  const keys = [
    (row: Row<TypesLeads.User>) => row.original.firstName + ' ' + row.original.lastName,
    (row: Row<TypesLeads.User>) => row.original.firstName + row.original.lastName,
    (row: Row<TypesLeads.User>) => row.original.title,
    (row: Row<TypesLeads.User>) => row.original.email,
    (row: Row<TypesLeads.User>) => row.original.locationName,
    (row: Row<TypesLeads.User>) => row.original.tags.map((tag: TypesLeads.Tag) => tag.tagName)
  ];
  const filteredRows = filterValues.reduceRight(
    (results, term) =>
      matchSorter(results, term, { keys, threshold: matchSorter.rankings.CONTAINS }),
    rows
  );
  return filteredRows;
};

export const getAvailabilityTitle = (availability?: number) => {
  return `Availability - ${Math.round((availability ?? 0) * 100)}%`;
};
