import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Row } from 'react-table';

import * as TypesLeads from 'types/leads';
import styles from './_displayUsers.module.css';
import UserDetails from './single-user/UserDetails';
import UserLeads from './single-user/UserLeads';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
interface Props {
  user?: TypesLeads.User;
  isLoading?: boolean;
  isLeadsView?: boolean;
  matchingLoaded?: boolean;
  hasReturned?: boolean;
  myLeads: boolean;
  userId?: string;
  selectedFlatRows: Row<TypesLeads.User>[]
  activeCheckedUserIndex: number;
  setActiveCheckedUserIndex: (index: number) => void;
  isForLead: boolean;
}

function UserOverview(props: Props) {
  const { user, matchingLoaded, isLeadsView, hasReturned, myLeads, selectedFlatRows, activeCheckedUserIndex, setActiveCheckedUserIndex, isForLead } = props;
  // const [activePage, setActivePage] = useState(hasReturned ? 'leads' : myLeads ? 'leads' : 'details');

  // useEffect(() => {
  //   if (myLeads) {
  //     setActivePage('leads')
  //   }
  // }, [myLeads]);

  if (user == null)
    return <Box component="div" className={styles['users-table-container-information']} />;

  const updateDisplaySelected = (direction: string) => {
    if (activeCheckedUserIndex === -1) {
      setActiveCheckedUserIndex(0);
    } else if (direction === 'up') {
      activeCheckedUserIndex == selectedFlatRows.length - 1
        ? setActiveCheckedUserIndex(0)
        : setActiveCheckedUserIndex(activeCheckedUserIndex + 1);
    } else if (direction === 'down') {
      activeCheckedUserIndex == 0
        ? setActiveCheckedUserIndex(selectedFlatRows.length - 1)
        : setActiveCheckedUserIndex(activeCheckedUserIndex - 1);
    }
  };

  return (
    <Box component="div" className={styles['users-table-container-information']}>
      {!matchingLoaded && <LoadingThreeDots left />}
      {matchingLoaded && user && <Fragment>
        <div>
          <div className={styles['users-table-container-information-header']}>
            <button
              disabled={selectedFlatRows.length < 1}
              className={styles['users-table-container-information-header-chevron-buttons']}
              onClick={() => updateDisplaySelected('down')}>
              <ChevronLeftRoundedIcon />
            </button>
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
            <button
              disabled={selectedFlatRows.length < 1}
              className={styles['users-table-container-information-header-chevron-buttons']}
              onClick={() => updateDisplaySelected('up')}>
              <ChevronRightRoundedIcon />
            </button>
          </div>
          {/* <div className={styles['users-table-container-information-sub-header']}>
            <button
              className={
                activePage === 'details'
                  ? styles['users-table-container-information-sub-header-button-active']
                  : styles['users-table-container-information-sub-header-button']
              }
              onClick={() => setActivePage('details')}>
              <span>Consultant details</span>
            </button>
            {!isForLead && (
              <button
                className={
                  activePage === 'leads'
                    ? styles['users-table-container-information-sub-header-button-active']
                    : styles['users-table-container-information-sub-header-button']
                }
                onClick={() => setActivePage('leads')}>
                <span>Matching leads</span>
              </button>
            )}
          </div> */}
        </div>
        {!isLeadsView && <UserDetails user={user} />}
        {/* {activePage === 'leads' && <UserLeads user={user} />} */}
      </Fragment>}
    </Box>
  );
}

export default UserOverview;