import React from 'react'
import * as TypesLeads from 'types/leads';
import { useNavigate } from 'react-router-dom';
import styles from './_displayLeads.module.css';
import { Tooltip } from '@mui/material';
import findCompetenceIcon from '../../../../assets/icons/findCompetenceIcon.png';


interface Props {
    lead: TypesLeads.Lead;
}

const RowFindCompetenceBtn = (props: Props) => {
    const { lead } = props;
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.stopPropagation();
        navigate({
            pathname: '/consultants',
            search: `?forLead=${lead.leadId}`,
        }, { state: { skip: 'false' } });
    }

    return (
        <Tooltip title="Find Competence">
            <button
                className={styles['row-icon-button']}
                onClick={(event) => handleClick(event)}>
                <img src={findCompetenceIcon} style={{ width: '24px', height: '24px' }} />
            </button>
        </Tooltip>
    )
}

export default RowFindCompetenceBtn