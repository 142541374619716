import styles from './_formActions.module.css';

interface Props {
  title: string;
  children?: boolean | JSX.Element | JSX.Element[];
}

function FormActionBar(props: Props) {
  const { title, children } = props;

  return (
    <div className={styles['form-action-bar']}>
      {title == 'Skills' ? (
        <div className={styles['form-action-bar-title-wrapper']}>
          <h3 className={styles['form-action-bar-title']}>{title}</h3>
          <p className={`${styles['form-action-bar-chip']} ${styles['form-action-bar-chip-required']}`}>Required</p>
          <p className={styles['form-action-bar-chip']}>Nice-to-have</p>
        </div>
      ) : (
        <h3 className={styles['form-action-bar-title']}>{title}</h3>
      )
      }
      <div className={styles['form-action-bar-content']}>{children}</div>
    </div >
  );
}

export default FormActionBar;
