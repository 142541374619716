import { store } from 'redux/store';
import { PropsWithChildren } from 'react';
import { ActionType, Cell, HeaderProps, Row, TableState } from 'react-table';
import {
  SEARCH_ANSWERED_ANSWERED,
  SEARCH_RESULT_WON,
  SEARCH_RESULT_LOST,
  FilterState,
  SEARCH_IRRELEVANT,
  SEARCH_FAVORITE,
  SEARCH_ASSIGNED
} from 'redux/filterReducer';

import * as TypesLeads from 'types/leads';

export const LEADS_TABLE_ROW_HEIGHT = 45;
export const LEADS_TABLE_HEAD_HEIGHT = 100;
export const LEADS_TABLE_FOOTER_HEIGHT = 50;

function getFilteredRowsForResult(filters: FilterState, rows: Row<TypesLeads.Lead>[]) {
  const isResultWon =
    filters.searchResult === SEARCH_RESULT_WON
      ? true
      : filters.searchResult === SEARCH_RESULT_LOST
      ? false
      : null;

  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead).result?.value === isResultWon
  );
}

function getFilteredRowsForAnswered(filters: FilterState, rows: Row<TypesLeads.Lead>[]) {
  const isAnswered = filters.searchAnswered === SEARCH_ANSWERED_ANSWERED;
  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead).answered?.value === isAnswered
  );
}

function getFilteredRowsForRelevant(filters: FilterState, rows: Row<TypesLeads.Lead>[]) {
  const isRelevant = filters.searchRelevant === SEARCH_IRRELEVANT;
  return rows.filter(
    (row: Row<TypesLeads.Lead>) =>
      (row.original as TypesLeads.Lead).irrelevant?.value === isRelevant
  );
}

function getFilteredRowsForFavorite(
  filters: FilterState,
  rows: Row<TypesLeads.Lead>[]
): Row<TypesLeads.Lead>[] {
  const isFavorite = filters.searchFavorite === SEARCH_FAVORITE;

  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead)?.isFavorite === isFavorite
  );
}

function getFilteredRowsForAssignedToMe(
  filters: FilterState,
  rows: Row<TypesLeads.Lead>[]
): Row<TypesLeads.Lead>[] {
  const { user: loggedInUser } = store.getState().auth;
  const shouldInclude = filters.searchAssigned === SEARCH_ASSIGNED ? true : false;
  if (filters.searchAssigned) {
    return rows.filter(
      (row: Row<TypesLeads.Lead>) =>
        (row.original as TypesLeads.Lead)?.assignedSalespersons?.includes(loggedInUser?.userId) ===
        shouldInclude
    );
  }
  return rows;
}

export function getStatusFilters(
  rows: Row<TypesLeads.Lead>[],
  id: string[],
  filterValue: FilterState
) {
  const filters = filterValue as FilterState;
  if (filters.searchAnswered != null) {
    rows = getFilteredRowsForAnswered(filters, rows);
  }

  if (filterValue.searchRelevant !== null) {
    rows = getFilteredRowsForRelevant(filters, rows);
  }

  if (filters.searchResult !== null) {
    rows = getFilteredRowsForResult(filters, rows);
  }

  if (filterValue.searchFavorite !== null) {
    rows = getFilteredRowsForFavorite(filters, rows);
  }

  if (filterValue.searchAssigned !== null) {
    rows = getFilteredRowsForAssignedToMe(filters, rows);
  }

  return rows;
}

interface GetConditionalSelectHeaderCheckboxProps {
  headerProps: PropsWithChildren<HeaderProps<TypesLeads.Lead>>;
  checkIfRowIsSelectable: (row: Row<TypesLeads.Lead>) => boolean;
  shouldSelectPage?: boolean;
}

export const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = true
}: GetConditionalSelectHeaderCheckboxProps) => {
  const checkIfAllSelectableRowsSelected = (rows: Row<TypesLeads.Lead>[]) =>
    rows.filter(checkIfRowIsSelectable).every((row: Row<TypesLeads.Lead>) => row.isSelected);

  const isSelectPage =
    shouldSelectPage &&
    headerProps.page?.filter(checkIfRowIsSelectable).some((row) => !row.isSelected);

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps();

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0;
  const checked = !disabled && checkIfAllSelectableRowsSelected(headerProps.rows);
  const indeterminate =
    !checked && headerProps.rows.some((row: Row<TypesLeads.Lead>) => row.isSelected);

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row: Row<TypesLeads.Lead>) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows;
      rows.forEach((row: Row<TypesLeads.Lead>) => {
        const checked = checkIfRowIsSelectable(row);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
  };

  return {
    ...checkboxProps,
    checked,
    indeterminate,
    disabled,
    onChange
  };
};

export const getCellTooltipText = (cell: Cell<TypesLeads.Lead>): string => {
  const leadData: TypesLeads.Lead = cell.row.original;
  if (cell.column.id === 'status') {
    const statuses: string[] = [];
    leadData.answered.value === true ? statuses.push('answered') : statuses.push('unanswered');
    leadData.result.value === true
      ? statuses.push('won')
      : leadData.result.value === false
      ? statuses.push('lost')
      : statuses.push('no result');
    return statuses.join(', ');
  }

  return cell.value?.toString();
};

export const getLeadsTablePageSize = (windowHeight: number): number => {
  const tableHeight = windowHeight - 150;
  const LEADS_TABLE_BODY_HEIGHT = tableHeight - LEADS_TABLE_HEAD_HEIGHT - LEADS_TABLE_FOOTER_HEIGHT;
  return Math.max(1, Math.floor(LEADS_TABLE_BODY_HEIGHT / LEADS_TABLE_ROW_HEIGHT));
};

export const leadsTableReducer = (
  newState: TableState<TypesLeads.Lead>,
  action: ActionType,
  previousState: TableState<TypesLeads.Lead>
): TableState<TypesLeads.Lead> => {
  if (action.type === 'gotoPage') {
    return newState;
  }
  return { ...newState, pageIndex: previousState.pageIndex };
};
