import LinearProgress from '@mui/material/LinearProgress';

import { getProgressValue } from './_functionsDisplayUsers';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';

interface Props {
  user: TypesLeads.User;
}

const UserTableRelevanceCell = (props: Props) => {
  const { user } = props;

  return (
    <>
      <div className={styles['user-table-relevance-cell-progress-wrap']}>
        <span>Skills</span>
        <LinearProgress
          variant="determinate"
          className={styles['user-table-relevance-cell-progress-bar']}
          color='secondary'
          sx={{
            backgroundColor: '#FBFBFB',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#399620'
            }
          }}
          value={getProgressValue(user.competenceStatus?.matchWeights?.tag)}
        />
      </div>

      <div className={styles['user-table-relevance-cell-progress-wrap']}>
        <span>Available</span>
        <LinearProgress
          variant="determinate"
          className={styles['user-table-relevance-cell-progress-bar']}
          color='secondary'
          sx={{
            backgroundColor: '#FBFBFB',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#399620'
            }
          }}
          value={getProgressValue(user.competenceStatus?.matchWeights?.availability)}
        />
      </div>

      <div className={styles['user-table-relevance-cell-progress-wrap']}>
        <span>Location</span>
        <LinearProgress
          variant="determinate"
          className={styles['user-table-relevance-cell-progress-bar']}
          color='secondary'
          sx={{
            backgroundColor: '#FBFBFB',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#399620'
            }
          }}
          value={getProgressValue(user.competenceStatus?.matchWeights?.location)}
        />
      </div>
    </>
  );
};

export default UserTableRelevanceCell;
