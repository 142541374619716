export function isListLengthOverLimit<T>(limitNumber: number, list?: T[]): boolean {
  if (!list) {
    return false;
  }

  return Boolean(list.length > limitNumber);
}

export function getLimitedList<T>(shouldDisplayAll: boolean, limitNumber: number, list?: T[]): T[] {
  if (!list) {
    return [];
  }

  const clonedList = [...list];
  return !shouldDisplayAll ? clonedList.slice(0, limitNumber) : clonedList;
}

/**
 * Function to limit the length of a text and provide an option to view more.
 * @param viewMore Indicates whether to show the full text or limit it.
 * @param length Maximum length of the text to display.
 * @param text The input text to be limited.
 * @returns An object containing the limited text and a flag indicating if the full text can be viewed.
 */
export function getLimitedText(
  viewMore: boolean,
  length: number,
  text?: string
): { text: string; canViewAll: boolean } {
  
  // If no text is provided, return a default message.
  if (!text) {
    return { text: 'No data', canViewAll: false };
  }

  // If the text length is within the limit, return the text with canViewAll as false.
  if (text.length <= length) {
    return { text: text, canViewAll: false };
  }

  // If viewMore is true, return the full text with canViewAll as true.
  if (viewMore) {
    return { text: text, canViewAll: true };
  }

  // If viewMore is false and text length exceeds the limit, truncate it.
  let result = text.slice(0, length);

  // Find the last occurrence of a whitespace after the specified length.
  const match = /\s/m.exec(text.slice(length));

  // If a whitespace is found, truncate the text to the nearest whitespace.
  if (match) {
    result = text.slice(0, length + match.index);
  }

  // Return the truncated text with canViewAll as true.
  return { text: result, canViewAll: true };
}

