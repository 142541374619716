import { neuronApi } from './api';

import * as TypesLeads from 'types/leads';
import * as TypesRedux from 'types/redux';

const commentApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllComments: builder.query<TypesLeads.Comment[], string>({
      query: (id: string) => ({
        url: `leads/${id}/comments`,
        method: 'GET'
      }),
      providesTags: ['Comments']
    }),
    addComment: builder.mutation<void, TypesRedux.AddCommentRequest>({
      query: ({ leadId, body }) => ({
        url: `leads/${leadId}/comments/add`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Comments']
    }),
    removeComment: builder.mutation<void, TypesRedux.RemoveCommentRequest>({
      query: ({ leadId, commentId }) => ({
        url: `leads/${leadId}/comments/${commentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Comments']
    })
  }),
  overrideExisting: false
});

export const { useGetAllCommentsQuery, useAddCommentMutation, useRemoveCommentMutation } =
  commentApiSlice;
