import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import * as TypesRedux from 'types/redux';
import { RootState } from 'redux/store';

const ProtectedRoute = () => {
  const { isLoggedIn }: TypesRedux.InitialStateAuth = useSelector((state: RootState) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
