import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';

import { topSkillsChartOptions as options } from 'constants/chartJS';
import { useGetLeadFrequenciesQuery } from 'redux/statisticsApi';
import { generateTopSkillsData } from './_functionsCharts';
import { TimePeriod } from '../time-period/TimePeriodSelection';

const numberOfSkills = 4;

interface Props {
  timePeriod: TimePeriod;
}
const SkillsLineChart = (props: Props) => {
  const { timePeriod } = props;

  const { data, error } = useGetLeadFrequenciesQuery(timePeriod.query());

  if (error) {
    return <ErrorMessage center />;
  }

  if (!data) {
    return <LoadingThreeDots />;
  }

  return <Line options={options} data={generateTopSkillsData(data, numberOfSkills)} />;
};

export default SkillsLineChart;
