import React from 'react';
import { getProgressValue } from '../_functionsDisplayUsers';
import * as TypesLeads from 'types/leads';
import stylesTwo from './_displayConsultantLeads.module.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ConsultantSkillProgress from './ConsultantSkillProgress';

type Props = {
  lead: TypesLeads.Lead;
  navigate: any;
  userId: number;
};

const ConsultantLeadsRow = (props: Props) => {
  const { lead, navigate, userId } = props;

  return (
    <div className={stylesTwo['users-selected-matching-leads-row']}>
      <div className={stylesTwo['users-selected-matching-leads-row-name']}>
        <p style={{ width: '190px' }}>
          {lead.companyName || 'N/A'}
        </p>
        <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '150px' }}>{lead.role || 'N/A'}</p>
      </div>
      <div className={stylesTwo['users-selected-matching-leads-row-skills']}>
        <ConsultantSkillProgress
          value={getProgressValue(lead.competenceStatus?.matchWeights?.tag)}
        />

        <ConsultantSkillProgress
          value={getProgressValue(lead.competenceStatus?.matchWeights?.availability)}
        />

        <ConsultantSkillProgress
          value={getProgressValue(lead.competenceStatus?.matchWeights?.location)}
        />
      </div>
      <div
        className={stylesTwo['users-selected-matching-leads-row-button']}
        onClick={() =>
          navigate(`/leads/${lead.leadId}`, {
            state: { fromConsultant: true, returnLocation: 'consultants', userId }
          })
        }>
        <p className={stylesTwo['users-selected-matching-leads-row-button-text']}>View lead</p>
        <ChevronRightRoundedIcon
          className={stylesTwo['users-selected-matching-leads-row-button-chevron']}
        />
      </div>
    </div>
  );
};

export default ConsultantLeadsRow;
