import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from './store';

import * as TypesRedux from 'types/redux';

const BACKEND_ENVIRONMENT = process.env.REACT_APP_BACKEND_TARGET;

const BACKEND_TARGETS = {
  PROD: 'neuron-375815.ey.r.appspot.com',
  DEV: 'dev-dot-neuron-375815.ey.r.appspot.com',
  LOCAL: 'localhost:7212'
};
const IS_LOCAL = BACKEND_ENVIRONMENT == 'LOCAL';

function getTargetHostname(): string {
  return BACKEND_TARGETS[BACKEND_ENVIRONMENT ?? 'DEV'] ?? BACKEND_TARGETS.DEV;
}

export function getBaseUrl(schema: 'http' | 'ws', path: string) {
  if (!IS_LOCAL) schema += 's';
  const url = `${schema}://${getTargetHostname()}/${path}`;
  return url;
}

export function getWSUrl() {
  return getBaseUrl('ws', 'api/neuronws');
}

export const neuronApi = createApi({
  reducerPath: 'neuronApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('http', 'api'),
    prepareHeaders: (headers: Headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Leads', 'Comments', 'Users', 'Favorites'],
  endpoints: (builder) => ({
    login: builder.mutation<TypesRedux.LoginResponse, TypesRedux.LoginRequest>({
      query: (body: TypesRedux.LoginRequest) => ({
        url: 'authorize',
        method: 'POST',
        body: body
      })
    }),
    refreshSession: builder.query<void, void>({
      query: () => ({
        url: 'refreshSession',
        method: 'GET'
      })
    })
  })
});

export const { useLoginMutation, useRefreshSessionQuery } = neuronApi;
