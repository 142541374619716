import { FormControl } from '@mui/material';
import { object, string, array, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import FormActionBar from '../form-actions/FormActionBar';
import FormActionButton from '../form-actions/FormActionButton';
import SkillsInput from './SkillsInput';

import { useEditLeadMutation } from 'redux/leadApi';
import * as TypesLeads from 'types/leads';

import styles from './_skills.module.css';

interface Props {
  lead: TypesLeads.Lead;
  isReadOnly: boolean;
}

interface SubmitValues {
  tags: TypesLeads.Tag[];
}

const SkillsForm = (props: Props) => {
  const { lead, isReadOnly } = props;
  const tags = lead?.tags || [];

  const skillsSchema = object({
    tags: array().of(
      object().shape({
        tagId: number().nullable(),
        tagName: string(),
        tagType: number().nullable()
      })
    )
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    resolver: yupResolver(skillsSchema),
    defaultValues: {
      tags
    }
  });

  const { replace } = useFieldArray({ control, name: 'tags' });

  const [editLead] = useEditLeadMutation();

  const handleReset = () => {
    reset();
  };

  const handleOnChange = (tags: TypesLeads.Tag[]) => {
    replace(tags);
  };

  const handleUpdate = (values: SubmitValues) => {
    const { tags } = values;
    editLead({
      leadId: lead.leadId,
      tags
    }).unwrap();
    reset({ tags });
  };

  return (
    <>
      <FormActionBar title="Skills">
        {isDirty && (
          <>
            <FormActionButton variant="clear" onClick={handleReset} />
            <FormActionButton variant="save" onClick={handleSubmit(handleUpdate)} />
          </>
        )}
      </FormActionBar>

      <FormControl className={styles['lead-form-skills-add']}>
        <Controller
          control={control}
          name="tags"
          render={({ field }) => (
            <SkillsInput skills={field.value} isReadOnly={isReadOnly} onChange={handleOnChange} />
          )}
        />
      </FormControl>
    </>
  );
};

export default SkillsForm;
